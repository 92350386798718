import React from 'react';
import { Link , useNavigate } from 'react-router-dom';
import './Sidebar.css';
import logo from '../images/logo.png';

const Sidebar = ({ setUser }) => {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  const dashBoard = () => {
    navigate('/dashboard');
  }

  return (
    <nav className="col-md-2 d-none d-md-block sidebar">
      <div className="sidebar-sticky">
        <img src={logo} alt="Best Kilo Logo" />

        <ul className="nav flex-column">
          <li className="nav-item">
          <h6 onClick={dashBoard} style={{cursor: "pointer"}}>Dashboard</h6>
          </li>
          
          <li className="nav-item">
            <h6>Orders</h6>
            <ul>
              <li><Link to="/orders/in-app" className="nav-link">In App Orders</Link></li>
              <li><Link to="/orders/on-call" className="nav-link">On Call Orders</Link></li>              
              {/* <li><Link to="/orders/on-call" className="nav-link">Scheduled Orders</Link></li> */}
              <li><Link to="/instance-ride" className="nav-link">Instance Ride Orders</Link></li>
              <li><Link to="/orders/all" className="nav-link">All Order List</Link></li>
            </ul>
          </li>
          
          <li className="nav-item">
            <h6>Customer Management</h6>
            <ul>
              <li><Link to="/customerlist" className="nav-link">Customer List</Link></li>
            </ul>
          </li>

          <li className="nav-item">
            <h6>Driver Management</h6>
            <ul>
              <li><Link to="/driverlist" className="nav-link">Driver List</Link></li>
              <li><Link to="/drivers/pending" className="nav-link">Pending Driver List</Link></li>
            </ul>
          </li>

          <li className="nav-item">
            <h6>Driver Wallet Top-up</h6>
            <ul>
              <li><Link to="/drivertopup" className="nav-link">Topup to Wallet</Link></li>
              <li><Link to="/drivertopup/history" className="nav-link">Top-up History</Link></li>
            </ul>
          </li>

          <li className="nav-item">
            <h6>User Management</h6>
            <ul>
              <li><Link to="/users" className="nav-link">User List</Link></li>
              <li><Link to="/users/forget-password" className="nav-link">Forget Password</Link></li>
            </ul>
          </li>

          <li className="nav-item">
            <h6>Settings</h6>
            <ul>
              <li><Link to="/configuration" className="nav-link">Config Setting</Link></li>
              <li><Link to="/extra-demand" className="nav-link">Extra Demands Setting</Link></li>
              <li><Link onClick={handleLogout} to="/" className="nav-link">Logout</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
