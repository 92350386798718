/* global google */
import React, { useEffect, useRef, useState } from "react";

const MapComponent = ({ onStartPointSelect, onEndPointSelect }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [markerStart, setMarkerStart] = useState(null);
  const [markerEnd, setMarkerEnd] = useState(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const initializeMap = () => {
      const initialMap = new google.maps.Map(mapRef.current, {
        center: { lat: 16.8409, lng: 96.1735 }, // Default center point (Yangon)
        zoom: 12,
      });

      setMap(initialMap);

      const startMarker = new google.maps.Marker({
        position: { lat: 16.8309, lng: 96.1735 },
        map: initialMap,
        draggable: true,
      });

      setMarkerStart(startMarker);

      const geocoder = new google.maps.Geocoder();

      const updateMarkerPosition = (marker, callback) => {
        marker.addListener("dragend", (event) => {
          const latLng = event.latLng;
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
              const position = {
                name: results[0].formatted_address,
                latitude: latLng.lat(),
                longitude: latLng.lng(),
              };
              callback(position);
            } else {
              console.error("Geocode was not successful for the following reason: " + status);
            }
          });
        });
      };

      updateMarkerPosition(startMarker, onStartPointSelect);
    };

    if (!map) {
      initializeMap();
    }
  }, [map, onStartPointSelect, onEndPointSelect]);

  useEffect(() => {
    if (map && markerStart && markerEnd) {
      markerStart.setMap(map);
    }
  }, [map, markerStart, markerEnd]);

  return <div ref={mapRef} style={{ height: "400px", width: "100%" }} />;
};

export default MapComponent;
