import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance } from "./axiosInstance";
import "./ConfigDetails.css";
import TopupLogo from "../images/success.png";

const UserCreate = ({ user, setUser }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState("");
  const [confirmNewUserPassword, setConfirmNewUserPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [authPassword, setAuthPassword] = useState("");

  useEffect(() => {
    if (
      newUserPassword &&
      confirmNewUserPassword &&
      newUserPassword !== confirmNewUserPassword
    ) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError("");
    }
  }, [newUserPassword, confirmNewUserPassword]);

  const handleCreate = async () => {
    if (passwordError) {
      return;
    }

    setLoading(true);
    try {
      const payload = {
        name,
        phone,
        email,
        role_id: parseInt(role, 10),
        password: newUserPassword,
        status: "Active",
      };

      const response = await axiosInstance.post(`/admins`, payload);

      if (response.status === 201) {
        setShowSecondPopup(true);
      } else {
        console.error(
          "Failed to create user:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleCreateClick = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password: authPassword,
      });
      if (response.status === 201) {
        const token = response.data.data.jwtToken;
        localStorage.setItem("authToken", token);
        setUser({ ...user, token });
        handleCreate();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/user/create`);
  };

  return (
    <div className="config-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Create User</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="form-container">
          <div className="config-edit">
            <h2 className="section-title">Create New User</h2>
            <div className="form-group-config">
              <div className="user-create">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="User Name"
                />
              </div>
              <div className="user-create">
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="form-group-config">
              <div className="user-create">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className="user-create">
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Role"
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="1">Admin</option>
                  <option value="2">Assistant Super Admin</option>
                  <option value="3">Super Admin</option>
                </select>
              </div>
            </div>
            <div className="form-group-config">
              <div className="user-create">
                <input
                  type="password"
                  value={newUserPassword}
                  onChange={(e) => setNewUserPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div className="user-create">
                <input
                  type="password"
                  value={confirmNewUserPassword}
                  onChange={(e) => setConfirmNewUserPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            {passwordError && (
              <div className="password-error">{passwordError}</div>
            )}
            <div className="button-update">
              <button
                className="update-button"
                onClick={handleCreateClick}
                disabled={loading || passwordError}
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter your password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={authPassword}
                onChange={(e) => setAuthPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onClose}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCreate;
