import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "./CustomerList.css";
import ChargesAmount from "./ChargesAmount";
import MatchingSetting from "./MatchingSetting";
import ReferralPointSetup from "./ReferralPointSetup";

const Configuration = ({ user, setUser }) => {
  const [activeTab, setActiveTab] = useState("chargesAmount");

  const renderTabContent = () => {
    switch (activeTab) {
      case "chargesAmount":
        return <ChargesAmount user={user} setUser={setUser} />;
      case "matchingSetting":
        return <MatchingSetting user={user} setUser={setUser} />;
      case "referralPointSetup":
        return <ReferralPointSetup user={user} setUser={setUser} />;
      default:
        return <ChargesAmount user={user} setUser={setUser} />;
    }
  };

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Configuration</h1>
            <p className="welcome-text">
              Hello Admin {user.name}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <h2 className="section-title">Config Setting List</h2>
        <div className="tabs2">
          <h5
            className={activeTab === "chargesAmount" ? "active-tab" : ""}
            onClick={() => setActiveTab("chargesAmount")}
          >
            Charges Amount
          </h5>
          <h5
            className={activeTab === "matchingSetting" ? "active-tab" : ""}
            onClick={() => setActiveTab("matchingSetting")}
          >
            Matching Setting
          </h5>
          <h5
            className={activeTab === "referralPointSetup" ? "active-tab" : ""}
            onClick={() => setActiveTab("referralPointSetup")}
          >
            Referral Point Setup
          </h5>
        </div>
        {renderTabContent()}
      </div>
    </div>
  );
};

export default Configuration;
