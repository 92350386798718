import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from './axiosInstance';
import './Login.css';
import loginImage from '../images/login.png';
import logoImage from '../images/logo.png';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email,
        password,
      });

      if (response.status === 201) {
        const { name, email, jwtToken } = response.data.data;
        const userData = { name, email, token: jwtToken };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('authToken', jwtToken);
        navigate('/dashboard');
      } else {
        console.error('Invalid credentials');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleForgetPassword = () => {
    navigate('/forget-password');
  }

  return (
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div className="col-md-7 d-none d-md-flex align-items-end justify-content-center">
          <img src={loginImage} alt="Login Illustration" className="login-img" />
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          <div className="login-box">
            <img src={logoImage} alt="Logo" className="logo-img" />
            <h2 className="text-center mb-4">Admin Login</h2>
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-3"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="btn btn-primary btn-block mb-3" onClick={handleLogin}>Login</button>
            <p onClick={handleForgetPassword} className='forget'>Forgot Password?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
