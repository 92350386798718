import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./OrderDetail.css";
import RouteLineEnd from "../images/route-line-end.png";
import RouteLine from "../images/route-line.png";

const OnCallDetails = ({ user, setUser }) => {
  const { id } = useParams();
  const [instanceData, setInstanceData] = useState(null);

  const mockData = {
    message: "Data fetched successfully.",
    data: {
      id: 2,
      user_id: 1,
      current_location: {
        name: "Current Location Name",
        latitude: 51.5074,
        longitude: 0.1278,
      },
      start_point: {
        name: "146 Pansodan Street, Yangon, Myanmar (Burma)",
        time: "09:56",
        latitude: 16.77315166666667,
        longitude: 96.16159,
      },
      middle_point: [
        {
          name: "Q5H5+2GM, Sule Pagoda Rd, Yangon, Myanmar (Burma)",
          endTime: "09:58",
          latitude: 16.77768166666667,
          longitude: 96.15893666666666,
          startTime: "09:58",
        },
        {
          name: "29 Gyo Phyu St, Yangon, Myanmar (Burma)",
          endTime: "09:59",
          latitude: 16.78405,
          longitude: 96.15965,
          startTime: "09:59",
        },
        {
          name: "Q5Q4+JVX, Zoological Garden Road, Yangon, Myanmar (Burma)",
          endTime: "09:59",
          latitude: 16.78905833333333,
          longitude: 96.15731666666667,
          startTime: "09:59",
        },
      ],
      end_point: {
        name: "R534+644, Kabar Aye Pagoda Rd, Yangon, Myanmar (Burma)",
        time: "10:01",
        latitude: 16.8029,
        longitude: 96.15562,
      },
      history_ride: [
        {
          name: "146 Pansodan Street, Yangon, Myanmar (Burma)",
          latitude: 51.49,
          longitude: 0.11,
        },
      ],
      status: "ACCEPTED",
      ride_type: "INSTANT_RIDE",
      driver_id: 1,
      distance: 3.18,
      is_out_of_town: false,
      total_price: 5180,
      stop_time: 15,
      total_time: 232,
      payment_status: true,
      payment_method: "Credit Card",
      caculation_details: {
        kilo: 1000,
        pickup: 1500,
        service: 500,
        waiting: 500,
      },
      demandArray: [
        {
          id: 5,
          fee: 200,
          name: "Gate Fee",
          quantity: 4,
        },
        {
          id: 5,
          fee: 1000,
          name: "Pet",
          quantity: 2,
        },
      ],
      cancel_reason: "Customer canceled the ride",
      code: null,
      created_date: "2024-08-14T14:51:22.829Z",
      updated_date: "2024-08-14T14:51:22.829Z",
      cancel_reason_image_id: null,
      tbl_Driver: {
        id: 1,
        name: "Andreas",
        phone: "+959421178725",
        code: "BK-0001",
        approved_date: null,
        pass_code: "$2b$10$yDVzVYFGNHF9C0UD98FkzebQWHjbypgQrAr5zwAOw/BL/jNrcqRgC",
        district_id: 15,
        state_id: 4,
        current_balance: 13500,
        township_id: 64,
        nrc_number: 12,
        nrc_township: "ThaGaKa",
        nrc_type: "N",
        nrc_id_number: 123456,
        address: "GG",
        driving_license: "E-007",
        status: "Approved",
        decline_reason: null,
        created_date: "2024-08-11T15:40:57.945Z",
        updated_date: "2024-08-20T10:00:07.433Z",
        fcm_token: null,
      },
      tbl_User: {
        id: 1,
        name: "Mr Hla",
        phone: "+959459060481",
        gender: "Male",
        dob: null,
        email: "Srhlancp@gmail.com",
        address: null,
        profile_picture: "users/profile_picture.jpg",
        created_date: "2024-08-12T15:02:56.020Z",
        updated_date: "2024-08-27T14:56:59.916Z",
        fcm_token:
          "dYCnwBQ9GEjxsSsipxFZfh:APA91bG9uxe3ZicWx7VC3yY0Klg1rWvQWYbJxhY6nWpHqtUClFpohB1jL4oqK_uc0x07pKuD7CR_kCY2dOdMvhtfYRgOupus9OD6g5AumA0hezDkOtzkgnAG-9118HISFcy74Euptsyf",
        status: "Suspended",
        townshipId: null,
        district_id: null,
        state_id: null,
      },
    },
  };

  const convertToTime = (input) => {
    if (typeof input === 'string' && input.match(/^\d{2}:\d{2}$/)) {
      return input;
    }
  
    const date = new Date(input);
    if (!isNaN(date.getTime())) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    }

    return "09:56";
  };

  useEffect(() => {
    const fetchInstanceData = async () => {
      try {
        const response = await axiosInstanceGet.get(`/on-call-order/${id}`);
        const data = response.data.data;

        data.start_point.time = convertToTime(data.start_point.time);
        data.middle_point = data.middle_point.map((point) => ({
          ...point,
          startTime: convertToTime(point.startTime),
          endTime: convertToTime(point.endTime),
        }));
        data.end_point.time = convertToTime(data.end_point.time);

        setInstanceData(data);
      } catch (error) {
        console.error("Error fetching order data:", error);
        const data = mockData.data;

        // Convert times to desired format for mock data
        data.start_point.time = convertToTime(data.start_point.time);
        data.middle_point = data.middle_point.map((point) => ({
          ...point,
          startTime: convertToTime(point.startTime),
          endTime: convertToTime(point.endTime),
        }));
        data.end_point.time = convertToTime(data.end_point.time);

        setInstanceData(data);
      }
    };

    fetchInstanceData();
  }, [id]);

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="order-detail-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="order-detail-title">Order Details</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
        </div>
        {instanceData && (
          <div className="order-info">
            <div className="info-header">
              <h4 className="section-title">
                Order List &gt; {instanceData.id}
              </h4>
            </div>
            <div className="card-container">
              <div className="card total-amount-card">
              <p className="mt-3">
                  {instanceData.total_price
                    ? `${instanceData.total_price} MMK`
                    : `${mockData.data.total_price} MMK`}
                </p>
                <span className="total mt-4">Total Amount</span>
              </div>
              <div className="card transaction-info">
                <h3>Transaction Information</h3>
                <ul>
                  {instanceData?.caculation_details?.pickup && (
                    <li>
                      Pickup Fee{" "}
                      <span>{instanceData.caculation_details.pickup} MMK</span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service && (
                    <li>
                      Service Charge{" "}
                      <span>
                        +{instanceData.caculation_details.service} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.kilo && (
                    <li>
                      Kilo Meter Per Charge{" "}
                      <span>+{instanceData.caculation_details.kilo} MMK</span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.waiting && (
                    <li>
                      Waiting Charge{" "}
                      <span>
                        +{instanceData.caculation_details.waiting} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.init_fee && (
                    <li>
                      Initial Fee{" "}
                      <span>
                        {instanceData.caculation_details.init_fee} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.ferry_fee && (
                    <li>
                      Ferry Fee{" "}
                      <span>
                        {instanceData.caculation_details.ferry_fee} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.oot_fee && (
                    <li>
                      Out of Town Distance & Charges{" "}
                      <span>{instanceData.caculation_details.oot_fee} MMK</span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.kilo_per_kyats && (
                    <li>
                      Define 1 Kilo per Kyats Charges{" "}
                      <span>
                        {instanceData.caculation_details.kilo_per_kyats} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.kilo_per_kyats_kilo && (
                    <li>
                      Define 1 Kilo per Kyats Charges (Kilo+){" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        +{instanceData.caculation_details.kilo_per_kyats_kilo}{" "}
                        MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.waiting_time && (
                    <li>
                      Waiting Time Charges{" "}
                      <span>
                        {instanceData.caculation_details.waiting_time} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_5000 && (
                    <li>
                      Service Charge (0-5,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_5000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_10000 && (
                    <li>
                      Service Charge (5,001 - 10,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_10000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_15000 && (
                    <li>
                      Service Charge (10,001 - 15,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_15000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_20000 && (
                    <li>
                      Service Charge (15,001 - 20,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_20000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_25000 && (
                    <li>
                      Service Charge (20,001 - 25,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_25000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_30000 && (
                    <li>
                      Service Charge (25,001 - 30,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_30000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_40000 && (
                    <li>
                      Service Charge (30,001 - 40,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_40000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_50000 && (
                    <li>
                      Service Charge (40,001 - 50,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_50000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_60000 && (
                    <li>
                      Service Charge (50,001 - 60,000){" "}
                      <span>
                        {instanceData.caculation_details.service_fee_60000} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.service_fee_60001 && (
                    <li>
                      Service Charge (60,001 - Unlimited){" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {instanceData.caculation_details.service_fee_60001} MMK
                      </span>
                    </li>
                  )}
                  {instanceData?.caculation_details?.oot_kilo_fee && (
                    <li>
                      Out of Town Distance & Charges (Kilo+){" "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        +{instanceData.caculation_details.oot_kilo_fee} MMK
                      </span>
                    </li>
                  )}
                  {instanceData.demandArray.map((demand, index) => (
                    <li key={index}>
                      {demand.quantity} x {demand.name}{" "}
                      <span>+{demand.fee} MMK</span>
                    </li>
                  ))}
                </ul>
                <div className="payment-method">
                  <div className="payment-type">
                    {instanceData.payment_method ||
                      mockData.data.payment_method}
                  </div>
                </div>
              </div>
              <div className="card travel-info">
                <h3>Travel Information</h3>
                <div className="timeline">
                  {instanceData.start_point && (
                    <div>
                      <img
                        src={RouteLine}
                        alt="Route Line"
                        className="distance-line"
                      />
                      <div className="timeline-item">
                        <div className="timeline-items route">
                          <span className="time">
                            {instanceData.start_point.time ||
                              mockData.data.start_point.time}
                          </span>
                        </div>
                        <div className="timeline-items">
                          <span className="location">
                            {instanceData.start_point.name ||
                              mockData.data.start_point.name}
                          </span>
                          <span className="start">Start</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {instanceData.middle_point.length > 0 &&
                    instanceData.middle_point.map((stop, index) => {
                      const isLastStop =
                        index === instanceData.middle_point.length - 1;
                      return (
                        <div key={index}>
                          <img
                            src={isLastStop ? RouteLineEnd : RouteLine}
                            alt="Route Line"
                            className="distance-line"
                          />
                          <div className="timeline-item">
                            <div className="timeline-items route">
                              <span className="time">
                                {stop.startTime || mockData.data.middle_point[index].startTime}
                              </span>
                              <span className="stop-time">-</span>
                              <span className="time">
                                {stop.endTime || mockData.data.middle_point[index].endTime}
                              </span>
                            </div>
                            <div className="timeline-items">
                              <span className="location">
                                {stop.name || mockData.data.middle_point[index].name}
                              </span>
                              <span className="stop">
                                Stop - Waiting Time:{" "}
                                {instanceData.stop_time || mockData.data.stop_time} mins
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {instanceData.end_point && (
                    <div className="timeline-item">
                      <div className="timeline-items route">
                        <span className="time">
                          {instanceData.end_point.time || mockData.data.end_point.time}
                        </span>
                      </div>
                      <div className="timeline-items">
                        <span className="location">
                          {instanceData.end_point.name || mockData.data.end_point.name}
                        </span>
                        <span className="finish">Finish</span>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="summary">
                    <div>
                      Travel Time{" "}
                      <span>
                        {formatTime(instanceData.total_time) ||
                          formatTime(mockData.data.total_time)}
                      </span>
                    </div>
                    <div>
                      Waiting Time{" "}
                      <span>
                        {formatTime(instanceData.stop_time) ||
                          formatTime(mockData.data.stop_time)}
                      </span>
                    </div>
                    <div>
                      Travel Distance{" "}
                      <span>
                        {instanceData.distance || mockData.data.distance} km
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnCallDetails;
