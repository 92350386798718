import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstanceGet } from "./axiosInstance";

const ReferralPointSetup = ({ user, setUser }) => {
  const [configurations, setConfigurations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axiosInstanceGet.get(
          "/dashboard-config/referral"
        );
        setConfigurations(response.data.data);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigurations();
  }, []);

  const viewDetails = (config) => {
    navigate(`/referraldetails/${config.id}`, {
      state: { config },
    });
  };

  return (
    <div>
      <table className="match-table">
        <thead>
          <tr>
            <th className="start">Config Name</th>
            <th>Point</th>
            <th>Created Datetime</th>
            <th className="end">Action</th>
          </tr>
        </thead>
        <tbody>
          {configurations.map((config) => (
            <tr key={config.id}>
              <td className="start">{config.name}</td>
              <td>{config.point}</td>
              <td>{new Date(config.created_date).toLocaleString()}</td>
              <td className="end">
                <button
                  className="edit-button"
                  onClick={() => viewDetails(config)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReferralPointSetup;
