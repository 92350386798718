import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";

const InAppOrders = ({ user, setUser }) => {
  const [orders, setOrders] = useState([]);
  const [canceledOrdersCount, setCanceledOrdersCount] = useState(0);
  const [acceptedOrdersCount, setAcceptedOrdersCount] = useState(0);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [activeTab, setActiveTab] = useState("COMPLETED");
  const [showFilter, setShowFilter] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstanceGet.get("/in-app-order?size=200", {
        params: {
          status: activeTab,
          name: nameFilter || undefined,
          phone: phoneFilter || undefined,
        },
      });
      setOrders(data?.data);

      // const fetchedOrders = response.data.data;
      // const declined = await axiosInstanceGet.get(
      //   `/in-app-order?status=CANCELED`
      // );
      // const CanceledDrivers = declined.data.data;
      // const accepted = await axiosInstanceGet.get(
      //   `/in-app-order?status=ACCEPTED`
      // );
      // const acceptedDrivers = accepted.data.data;
      // const completed = await axiosInstanceGet.get(
      //   `/in-app-order?status=COMPLETED`
      // );
      // const completedDrivers = completed.data.data;
      // setOrders(fetchedOrders);

      // if (activeTab === "COMPLETED") {
      //   setCompletedOrdersCount(fetchedOrders.length);
      //   setCanceledOrdersCount(CanceledDrivers.length);
      //   setAcceptedOrdersCount(acceptedDrivers.length);
      // } else if (activeTab === "CANCELED") {
      //   setCanceledOrdersCount(CanceledDrivers.length);
      // } else if (activeTab === "ACCEPTED") {
      //   setAcceptedOrdersCount(acceptedDrivers.length);
      // }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
      setTabLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNameFilter(value);
    } else if (name === "phone") {
      setPhoneFilter(value);
    }
  };

  const handleResultClick = () => {
    // fetchOrders();
  };

  const handleExportClick = () => {
    setNameFilter("");
    setPhoneFilter("");
    // fetchOrders();
  };

  const handleTabChange = (tab) => {
    if (!tabLoading) {
      setTabLoading(true);
      setActiveTab(tab);
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const viewDetails = (order) => {
    navigate(`/in-app-details/${order.id}`, { state: { order } });
  };

  useEffect(() => {
    fetchOrders();
  }, [activeTab]);

  // Pagination Logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentOrders = orders.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(orders.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">In-App Orders List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
        </div>
        <div className="header">
          <div className="tabs">
            <button
              className={`tab-button ${
                activeTab === "COMPLETED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("COMPLETED")}
              disabled={tabLoading}
            >
              Completed ({completedOrdersCount})
            </button>
            <button
              className={`tab-button ${
                activeTab === "CANCELED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("CANCELED")}
              disabled={tabLoading}
            >
              Cancelled ({canceledOrdersCount})
            </button>
            <button
              className={`tab-button ${
                activeTab === "ACCEPTED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("ACCEPTED")}
              disabled={tabLoading}
            >
              Active ({acceptedOrdersCount})
            </button>
          </div>
          <div className="filter-container">
            <button className="filter-button" onClick={toggleFilter}>
              <span data-feather="filter"></span> Filter
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Filter</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={nameFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={phoneFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-buttons">
              <button className="export-button" onClick={handleExportClick}>
                Export
              </button>
              <button className="result-button" onClick={handleResultClick}>
                Result
              </button>
            </div>
          </div>
        )}

        {loading ? (
          <div className="loading-spinner">Loading...</div> // Display loading spinner
        ) : (
          <table
            className={`order-table ${orders.length === 0 ? "no-data" : ""}`}
          >
            <thead>
              <tr>
                <th className="start">Order ID</th>
                <th>Customer</th>
                <th>Driver</th>
                <th>From</th>
                <th>To</th>
                <th>Request Datetime</th>
                <th className="end">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order) => (
                <tr key={order.id}>
                  <td className="start">#{indexOfFirstRow + order.code}</td>
                  <td>{order.tbl_User?.name}</td>
                  <td>{order.tbl_Driver?.name || "-"}</td>
                  <td>{order.start_point.name}</td>
                  <td>{order.end_point.name}</td>
                  <td>{new Date(order.created_date).toLocaleString()}</td>
                  <td className="end">
                    <button
                      className="edit-button"
                      onClick={() => viewDetails(order)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination Controls */}
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {currentPage - 1}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage)}
                  disabled={currentPage === 2}
                >
                  {currentPage}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages - 1}
                >
                  ...
                </button>
              )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InAppOrders;
