export const configNames = {
    init_fee: "Initial Fee",
    service_fee_5000: "Service Charge (0-5,000)",
    service_fee_10000: "Service Charge (5,001 - 10,000)",
    service_fee_15000: "Service Charge (10,001 - 15,000)",
    service_fee_20000: "Service Charge (15,001 - 20,000)",
    service_fee_25000: "Service Charge (20,001 - 25,000)",
    service_fee_30000: "Service Charge (25,001 - 30,000)",
    service_fee_40000: "Service Charge (30,001 -  40,000)",
    service_fee_50000: "Service Charge (40,001 - 50,000)",
    service_fee_60000: "Service Charge (50,001 - 60,000)",
    service_fee_60001: "Service Charge (60,001 - Unlimited)",
    waiting_time: "Waiting Time Charges",
    ferry_fee: "Ferry Fee",
    oot_fee: "Out of Town Distance & Charges",
    oot_kilo_fee: "Out of Town Distance & Charges (Kilo+)",
    customer_service_fee: "Customer Service Fee (Over 1 Kilo)",
    kilo_per_kyats: "Define 1 Kilo per Kyats Charges",
    kilo_per_kyats_kilo: "Define 1 Kilo per Kyats Charges (Kilo+)",
  };