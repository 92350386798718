import React, { useEffect, useState } from "react";
import { axiosInstanceGet } from "./axiosInstance";
import Sidebar from "./Sidebar";
import "./Dashboard.css";

const Dashboard = ({ user, setUser }) => {
  const [inAppOrders, setInAppOrders] = useState([]);
  const [onCallOrders, setOnCallOrders] = useState([]);
  const [inAppOrderStats, setInAppOrderStats] = useState({
    active: 0,
    cancelled: 0,
    successful: 0,
  });
  const [onCallOrderStats, setOnCallOrderStats] = useState({
    pending: 0,
    active: 0,
    cancelled: 0,
    successful: 0,
  });

  useEffect(() => {
    const fetchOrdersData = async () => {
      try {
        // Fetch In-App Orders
        const inAppResponse = await axiosInstanceGet.get("/in-app-order");
        if (inAppResponse.status === 200) {
          const inAppData = inAppResponse.data.data;
          setInAppOrders(inAppData);

          // Calculate statistics for In-App Orders
          const inAppOrderStats = {
            active: inAppData.filter(order => order.status === "ACTIVE").length,
            cancelled: inAppData.filter(order => order.status === "CANCELLED").length,
            successful: inAppData.filter(order => order.status === "SUCCESSFUL").length,
          };
          setInAppOrderStats(inAppOrderStats);
        } else {
          console.error("Failed to fetch In-App Orders data:", inAppResponse.status, inAppResponse.statusText);
        }

        // Fetch On-Call Orders
        const onCallResponse = await axiosInstanceGet.get("/on-call-order");
        if (onCallResponse.status === 200) {
          const onCallData = onCallResponse.data.data;
          setOnCallOrders(onCallData);

          // Calculate statistics for On-Call Orders
          const onCallOrderStats = {
            pending: onCallData.filter(order => order.status === "PENDING").length,
            active: onCallData.filter(order => order.status === "ACTIVE").length,
            cancelled: onCallData.filter(order => order.status === "CANCELLED").length,
            successful: onCallData.filter(order => order.status === "SUCCESSFUL").length,
          };
          setOnCallOrderStats(onCallOrderStats);
        } else {
          console.error("Failed to fetch On-Call Orders data:", onCallResponse.status, onCallResponse.statusText);
        }
      } catch (error) {
        console.error("Error fetching orders data:", error);
      }
    };

    fetchOrdersData();
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <header className="dashboard-header">
          <div className="title-container">
            <h1>Dashboard</h1>
            <p>Hello Admin {user ? user.name : "Guest"}</p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/*avatar*/}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </header>
        <div className="dashboard">
          <h2 className="section-title">In App Orders (Today)</h2>
          <div className="card-grid">
            <div className="card">
              <h3>Active Customer Orders</h3>
              <p>Order by Customer Mobile App</p>
              <h2>{inAppOrderStats.active}</h2>
              <a href="/orders/in-app">View Details</a>
            </div>
            <div className="card">
              <h3>Cancelled Orders (Today)</h3>
              <p>Order by Customer Mobile App</p>
              <h2>{inAppOrderStats.cancelled}</h2>
              <a href="/orders/in-app">View Details</a>
            </div>
            <div className="card">
              <h3>Successful Orders (Today)</h3>
              <p>Order by Customer Mobile App</p>
              <h2>{inAppOrderStats.successful}</h2>
              <a href="/orders/in-app">View Details</a>
            </div>
          </div>

          <h2 className="section-title">On Call Orders (Today)</h2>
          <div className="card-grid">
            <div className="card">
              <h3>Pending On Call Orders</h3>
              <p>Order by Customer Phone Call</p>
              <h2>{onCallOrderStats.pending}</h2>
              <a href="/orders/on-call">View Details</a>
            </div>
            <div className="card">
              <h3>Active On Call Orders</h3>
              <p>Order by Customer Phone Call</p>
              <h2>{onCallOrderStats.active}</h2>
              <a href="orders/on-call">View Details</a>
            </div>
            <div className="card">
              <h3>Cancelled On Call Orders (Today)</h3>
              <p>On Call Successful Orders Lists</p>
              <h2>{onCallOrderStats.cancelled}</h2>
              <a href="orders/on-call">View Details</a>
            </div>
            <div className="card">
              <h3>Successful On Call Orders (Today)</h3>
              <p>On Call Successful Orders Lists</p>
              <h2>{onCallOrderStats.successful}</h2>
              <a href="orders/on-call">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
