import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./DriverTopup.css";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import TopupLogo from "../images/success.png";
import MapsLogo from "../images/maps.png";
import MapComponent from "./MapComponent";
import MapComponent2 from "./MapComponent2";

const OnCallCreate = ({ user, setUser }) => {
  const [driverPhone, setDriverPhone] = useState("");
  const [driverName, setDriverName] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [showMap, setShowMap] = useState(null); // null for no map, 'start' for start point, 'end' for end point
  const navigate = useNavigate();

  const handleCheck = async () => {
    try {
      const response = await axiosInstanceGet.get("/users", {
        params: { phone: driverPhone || undefined },
      });

      const driverDataArray = response.data.data;

      if (driverDataArray.length > 0) {
        const driverData = driverDataArray[0];
        setDriverName(driverData.name || "-");
      } else {
        setPopupMessage("No customer data found.");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setPopupMessage("Failed to fetch customer data.");
    }
  };

  const handleSubmit = async () => {
    if (!startPoint || !endPoint) {
      setPopupMessage("Please select both start and end points.");
      return;
    }

    try {
      const payload = {
        start_point: {
          name: startPoint.name,
          latitude: startPoint.latitude,
          longitude: startPoint.longitude,
        },
        end_point: {
          name: endPoint.name,
          latitude: endPoint.latitude,
          longitude: endPoint.longitude,
        },
      };

      const response = await axiosInstance.post("/on-call-order", payload);

      setPopupMessage(response.data.message);
      setShowPopup(false);
      setShowSecondPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateClick = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        handleSubmit();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    navigate(`/orders/on-call`);
    setShowSecondPopup(false);
  };

  // Callback function for selecting the start point
  const onStartPointSelect = (point) => {
    setStartPoint(point);
  };

  // Callback function for selecting the end point
  const onEndPointSelect = (point) => {
    setEndPoint(point);
  };

  const mapSubmit = () => {
    setShowMap(null);
  };

  const handleSelectStartPointClick = () => {
    setShowMap("start");
  };

  const handleSelectEndPointClick = () => {
    setShowMap("end");
  };

  return (
    <div className="driver-topup-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="driver-topup-title">Create On Call Orders</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="topup-form">
          <div style={{ display: "-webkit-inline-box", gap: "10px" }}>
            {showMap && <img src={MapsLogo} alt="Logo" />}
            <h2>
              {showMap === "start"
                ? "Select Start Point"
                : showMap === "end"
                ? "Select End Point"
                : "Order Create Form"}
            </h2>
          </div>
          {showMap === "start" && (
            <div className="form-group-config">
              <div className="user-create" style={{ marginRight: "25%" }}>
                <input
                  placeholder="Select start point"
                  value={startPoint ? startPoint.name : ""}
                  readOnly
                  onClick={handleSelectStartPointClick}
                />
              </div>
              <div className="button-topup1">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    setStartPoint(null);
                    setShowMap(null);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="button-topup1">
                <button
                  type="submit"
                  className="update-button"
                  onClick={() => {
                    setShowMap(null);
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          )}
          {showMap === "end" && (
            <div className="form-group-config">
              <div className="user-create" style={{ marginRight: "25%" }}>
                <input
                  placeholder="Select end point"
                  value={endPoint ? endPoint.name : ""}
                  readOnly
                  onClick={handleSelectEndPointClick}
                />
              </div>
              <div className="button-topup1">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    setStartPoint(null);
                    setShowMap(null);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="button-topup1">
                <button
                  type="submit"
                  className="update-button"
                  onClick={() => {
                    setShowMap(null);
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          )}
          {!showMap && (
            <>
              <div className="form-group-config">
                <div className="user-create">
                  <input
                    type="text"
                    value={driverPhone}
                    onChange={(e) => setDriverPhone(e.target.value)}
                    placeholder="Mobile"
                  />
                </div>
                <div className="button-topup1">
                  <button
                    type="button"
                    className="update-button"
                    onClick={handleCheck}
                  >
                    Check
                  </button>
                </div>
                <div className="user-create">
                  <input
                    value={driverName}
                    readOnly
                    type="text"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="form-group-config">
                <div className="user-create">
                  <input
                    placeholder="Select start point"
                    value={startPoint ? startPoint.name : ""}
                    readOnly
                    onClick={handleSelectStartPointClick}
                  />
                </div>
                <br />
                <div className="user-create">
                  <input
                    placeholder="Select end point"
                    value={endPoint ? endPoint.name : ""}
                    readOnly
                    onClick={handleSelectEndPointClick}
                  />
                </div>
              </div>
              <div className="button-topup1">
                <button
                  type="submit"
                  className="update-button"
                  onClick={handleUpdateClick}
                  disabled={!startPoint || !endPoint}
                >
                  Submit
                </button>
              </div>
            </>
          )}
          {showMap === "start" && (
            <MapComponent onStartPointSelect={onStartPointSelect} />
          )}
          {showMap === "end" && (
            <MapComponent2 onEndPointSelect={onEndPointSelect} />
          )}
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onClose}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>On Call Order Created</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnCallCreate;
