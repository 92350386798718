import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./CustomerList.css";
import TopupLogo from "../images/success.png";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";

const ExtraDemand = ({ user, setUser }) => {
  const [demands, setDemands] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDemands = async () => {
      try {
        const response = await axiosInstanceGet.get(`/extra-demands`);
        if (response.status === 200) {
          setDemands(response.data.data);
        } else {
          console.error(
            "Failed to fetch demands:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching demands:", error);
      }
    };

    fetchDemands();
  }, []);

  const viewDetails = (demand) => {
    navigate(`/demanddetails/${demand.id}`, {
      state: { demand },
    });
  };

  const createNew = () => {
    navigate(`/demandcreate`);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(`/extra-demands/${selectedDemand.id}`);

      if (response.status === 200) {
        setShowSecondPopup(true);
        setDemands((prevDemands) =>
          prevDemands.filter((demand) => demand.id !== selectedDemand.id)
        );
      } else {
        console.error(
          "Failed to delete demand:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting demand:", error);
    } finally {
      setLoading(false);
      setShowDeletePopup(false);
    }
  };

  const handleDeleteClick = (demand) => {
    setSelectedDemand(demand);
    setShowDeletePopup(true);
  };

  const onCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteConfirm = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
          email: user.email,
          password,
        }
      );
      if (response.status === 201) {
        handleDelete();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
  };

  const renderRow = (demand) => (
    <tr key={demand.id}>
      <td className="start">{demand.name}</td>
      <td>{demand.fee}</td>
      <td>{new Date(demand.created_date).toLocaleString()}</td>
      <td className="end">
        <button className="edit-button" onClick={() => viewDetails(demand)}>
          Edit
        </button>
        <button
          className="delete-button"
          onClick={() => handleDeleteClick(demand)}
        >
          Delete
        </button>
      </td>
    </tr>
  );

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Extra Demand Charges</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <h2 className="section-title1">Additional Charges List</h2>
          <div className="button-updates">
            <button className="update-button" onClick={createNew}>
              + Create New
            </button>
          </div>
        </div>
        <table className="demand-table">
          <thead>
            <tr>
              <th className="start">Charges Name</th>
              <th>Amount</th>
              <th>Created Datetime</th>
              <th className="end">Action</th>
            </tr>
          </thead>
          <tbody>{demands.map((demand) => renderRow(demand))}</tbody>
        </table>
      </div>
      {showDeletePopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onCloseDeletePopup}>Cancel</p>
              <p onClick={handleDeleteConfirm}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Delete Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraDemand;
