import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";

const PendingDriverList = ({ user, setUser }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectDriversCount, setRejectedDriversCount] = useState(0);
  const [pendingDriversCount, setPendingDriversCount] = useState(0);
  const [activeTab, setActiveTab] = useState("Pending");
  const [showFilter, setShowFilter] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState();
  const [registerFromFilter, setRegisterFromFilter] = useState("");
  const [registerToFilter, setRegisterToFilter] = useState("");
  const [townshipFilter, setTownshipFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [isFetching, setIsFetching] = useState(false); // New state for fetching
  const navigate = useNavigate();

  const fetchDrivers = async () => {
    setLoading(true);
    setIsFetching(true); // Start fetching
    try {
      const formattedRegisterFrom = registerFromFilter
        ? new Date(registerFromFilter).toISOString()
        : undefined;
      const formattedRegisterTo = registerToFilter
        ? new Date(registerToFilter).toISOString()
        : undefined;

      const response = await axiosInstanceGet.get("/drivers?size=200", {
        params: {
          status: activeTab,
          name: nameFilter || undefined,
          phone: phoneFilter,
          id: userIdFilter || undefined,
          register_start_date: formattedRegisterFrom,
          register_end_date: formattedRegisterTo,
          // township: townshipFilter,
          // city: cityFilter,
        },
      });
      const fetchedDrivers = response.data.data;
      // const declined = await axiosInstanceGet.get(`/drivers?status=Declined`);
      // const declinedDrivers = declined.data.data;
      setDrivers(fetchedDrivers);

      // if (activeTab === "Pending") {
      //   setPendingDriversCount(fetchedDrivers.length);
      //   setRejectedDriversCount(declinedDrivers.length);
      // } else if (activeTab === "Declined") {
      //   setRejectedDriversCount(declinedDrivers.length);
      // }
    } catch (error) {
      console.error("Error fetching drivers:", error);
    } finally {
      setLoading(false);
      setIsFetching(false); // End fetching
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setNameFilter(value);
        break;
      case "phone":
        setPhoneFilter(value);
        break;
      case "userId":
        setUserIdFilter(value);
        break;
      case "registerFrom":
        setRegisterFromFilter(value);
        break;
      case "registerTo":
        setRegisterToFilter(value);
        break;
      case "township":
        setTownshipFilter(value);
        break;
      case "city":
        setCityFilter(value);
        break;
      default:
        break;
    }
  };

  const handleResultClick = () => {
    // fetchDrivers();
  };

  const handleExportClick = () => {
    setNameFilter("");
    setPhoneFilter("");
    setUserIdFilter();
    setRegisterFromFilter("");
    setRegisterToFilter("");
    setTownshipFilter("");
    setCityFilter("");

    // fetchDrivers();
  };

  useEffect(() => {
    fetchDrivers();
  }, [activeTab]);

  const handleTabChange = (tab) => {
    if (!isFetching) {
      // Prevent tab change if fetching
      setActiveTab(tab);
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const viewDetails = (driver) => {
    navigate(`/driverdetails/pending/${driver.id}`, { state: { driver } });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate pagination data
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRides = drivers.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(drivers.length / rowsPerPage);

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Driver List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="header">
          <div className="title-container">
            <div className="tabs">
              <button
                className={`tab-button ${
                  activeTab === "Pending" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Pending")}
              >
                Pending ({pendingDriversCount})
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Declined" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Declined")}
              >
                Rejected ({rejectDriversCount})
              </button>
            </div>
          </div>
          <div className="filter-container">
            <button className="filter-button" onClick={toggleFilter}>
              <span data-feather="filter"></span> Filter
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Driver Filter</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={nameFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={phoneFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>User ID</label>
              <input
                type="number"
                name="userId"
                value={userIdFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Register From</label>
              <input
                type="date"
                name="registerFrom"
                value={registerFromFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Register To</label>
              <input
                type="date"
                name="registerTo"
                value={registerToFilter}
                onChange={handleFilterChange}
              />
            </div>
            {/* <div className="filter-option">
              <label>Township</label>
              <select name="township" value={townshipFilter} onChange={handleFilterChange}>
                <option value="">Select Township</option>
              </select>
            </div>
            <div className="filter-option">
              <label>City</label>
              <select name="city" value={cityFilter} onChange={handleFilterChange}>
                <option value="">Select City</option>
              </select>
            </div> */}
            <div className="filter-buttons">
              <button className="export-button" onClick={handleExportClick}>
                Export
              </button>
              <button className="result-button" onClick={handleResultClick}>
                Result
              </button>
            </div>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <table
            className={`driver-table ${drivers.length === 0 ? "no-data" : ""}`}
          >
            <thead>
              <tr>
                <th className="start">Driver Name</th>
                <th>Phone</th>
                <th>NRC</th>
                <th>Driving License No</th>
                <th>Township</th>
                <th>City</th>
                <th>Registered Datetime</th>
                <th>Status</th>
                <th className="end">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentRides.map((driver) => (
                <tr key={driver.id}>
                  <td className="start">{driver.name}</td>
                  <td>{driver.phone}</td>
                  <td>{`${driver.nrc_number}/${driver.nrc_township}(N)${driver.nrc_id_number}`}</td>
                  <td>{driver.driving_license}</td>
                  <td>{driver.tbl_township.eng}</td>
                  <td>{driver.tbl_state.capital}</td>
                  <td>{new Date(driver.created_date).toLocaleString()}</td>
                  <td>
                    <button className="detail-button">{driver.status}</button>
                  </td>
                  <td className="end">
                    <button
                      className="edit-button"
                      onClick={() => viewDetails(driver)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {currentPage - 1}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage)}
                  disabled={currentPage === 2}
                >
                  {currentPage}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages - 1}
                >
                  ...
                </button>
              )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingDriverList;
