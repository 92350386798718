import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet, axiosInstance } from "./axiosInstance";
import "./CustomerDetails.css";
import TopupLogo from "../images/accept.png";
import RejectLogo from "../images/reject.png";

const PendingDriverDetails = ({ user, setUser }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Driver Information");
  const [driverData, setDriverData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [state, setState] = useState("");
  const [township, setTownship] = useState("");
  const [address, setAddress] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [carModel, setCarModel] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [businessLicenseImage, setBusinessLicenseImage] = useState(null);
  const [vehicleLicenseFrontImage, setVehicleLicenseFrontImage] =
    useState(null);
  const [vehicleLicenseBackImage, setVehicleLicenseBackImage] = useState(null);

  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const response = await axiosInstanceGet.get(`/drivers/${id}`);
        setDriverData(response.data.data);
        setState(response.data.data.tbl_state.eng);
        setTownship(response.data.data.tbl_township.eng);
        setAddress(response.data.data.address);
        setVehicleNumber(response.data.data.DriverInformation.vehicle_number);
        setVehicleType(response.data.data.DriverInformation.vehicle_type);
        setCarModel(response.data.data.DriverInformation.car_model);
        setFuelType(response.data.data.DriverInformation.fuel_type);
      } catch (error) {
        console.error("Error fetching driver data:", error);
      }
    };

    fetchDriverData();
  }, []);

  const handleApproveClick = () => {
    setAction("approve");
    setShowPopup(true);
  };

  const handleRejectClick = () => {
    setAction("reject");
    setShowPopup(true);
  };

  const handleUpdateClick = () => {
    setAction("update");
    setShowPopup(true);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      console.error("User is not logged in or email is not available.");
      return;
    }

    try {
      const loginResponse = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });

      if (loginResponse.status === 201) {
        if (action === "approve") {
          await handleApprove();
        }
        if (action === "update") {
          await handleUpdate();
        } else {
          setShowPopup(false);
          setShowSecondPopup(true);
        }
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.patch(`/drivers/approve/${id}`, {
        password,
      });

      if (response.status === 200) {
        setShowSuccessPopup(true);
      } else {
        console.error(
          "Failed to approve driver:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error approving driver:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", driverData.name);
      formData.append("phone", driverData.phone);
      formData.append("driving_license", driverData.nrc_number);
      formData.append("nrc_number", driverData.nrc_number);
      formData.append("nrc_township", driverData.nrc_township);
      formData.append("nrc_type", driverData.nrc_type);
      formData.append("nrc_id_number", driverData.nrc_id_number);
      formData.append("city_id", driverData.tbl_state.id);
      formData.append("township_id", driverData.tbl_township.id);
      formData.append("address", driverData.address);
      // formData.append('is_rent', driverData.is_rent);
      formData.append(
        "vehicle_number",
        driverData.DriverInformation.vehicle_number
      );
      formData.append(
        "vehicle_type",
        driverData.DriverInformation.vehicle_type
      );
      formData.append("car_model", driverData.DriverInformation.car_model);
      formData.append("fuel_type", driverData.DriverInformation.fuel_type);

      // Append images
      formData.append(
        "driving_license_back_image",
        driverData.DriverInformation.driving_license_back_image.file
      );
      formData.append(
        "driving_license_front_image",
        driverData.DriverInformation.driving_license_front_image.file
      );
      formData.append(
        "business_license_image",
        driverData.DriverInformation.business_license_image.file
      );
      formData.append(
        "vehicle_license_front_image",
        driverData.DriverInformation.vehicle_license_front_image.file
      );
      formData.append(
        "vehicle_license_back_image",
        driverData.DriverInformation.vehicle_license_back_image.file
      );

      const response = await axiosInstance.patch(`/drivers/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        setShowUpdatePopup(true);
      } else {
        console.error(
          "Failed to update driver:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "Error updating driver:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleReject = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.patch(`/drivers/reject/${id}`, {
        reason,
      });

      if (response.status === 200) {
        setShowRejectPopup(true);
      } else {
        console.error(
          "Failed to reject driver:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error rejecting driver:", error);
    } finally {
      setLoading(false);
      setShowSecondPopup(false);
    }
  };

  if (!driverData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customer-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-details-title">Driver Details</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="customer-details">
          <div className="customer-info">
            <div className="info-header1" style={{justifyContent: "flex-start"}}>
              <a href="/drivers/pending">Driver List </a><h4> &gt; Details</h4>
            </div>
          </div>
          <div className="tab-container">
            <div
              className={`tab ${
                activeTab === "Driver Information" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Driver Information")}
            >
              Driver Information
            </div>
            <div
              className={`tab ${
                activeTab === "Vehicle Information" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Vehicle Information")}
            >
              Vehicle Information
            </div>
            <div className="status-tab">
              <button
                className={`status-button update`}
                onClick={handleUpdateClick}
                disabled={loading}
              >
                Update
              </button>
              <button
                className={`status-button approved`}
                onClick={handleApproveClick}
                disabled={loading}
              >
                Approve
              </button>
              <button
                className={`status-button reject`}
                onClick={handleRejectClick}
                disabled={loading}
              >
                Reject
              </button>
            </div>
          </div>
          {activeTab === "Driver Information" && (
            <div className="tab-content">
              <h4 className="mb-4">Driver Information</h4>
              <div className="customer-info2">
                <div className="avatar">
                  <img
                    className="circle"
                    src={driverData.DriverInformation.driver_profile_image.url}
                    alt="Driver"
                  />
                </div>
                <div className="details">
                  <p>
                    Name
                    <br /> <strong>{driverData.name}</strong>{" "}
                  </p>
                  <p>
                    Phone
                    <br /> <strong>{driverData.phone}</strong>
                  </p>
                  <p>
                    Driving License No
                    <br /> <strong>{driverData.nrc_number}</strong>
                  </p>
                  <div className="license-images flexer">
                    <div>
                      <h6>Driving License (front)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .driving_license_front_image.url
                        }
                        alt="Driving License Front"
                      />
                    </div>
                    <div>
                      <h6>Driving License (back)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .driving_license_back_image.url
                        }
                        alt="Driving License Back"
                      />
                    </div>
                  </div>
                  <p>
                    NRC Number
                    <br />{" "}
                    <strong>
                      {driverData.nrc_township}({driverData.nrc_type})
                      {driverData.nrc_id_number}
                    </strong>
                  </p>
                  <div className="flexer">
                    <div className="form-groups">
                      <label>City</label>
                      <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className="form-groups">
                      <label>Township</label>
                      <input
                        type="text"
                        value={township}
                        onChange={(e) => setTownship(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-groups">
                    <label>Address</label>
                    <br />
                    <textarea
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Vehicle Information" && (
            <div className="tab-content">
              <h4 className="mb-4">Vehicle Information</h4>
              <div className="customer-info3">
                <div className="details">
                  <div className="form-groups">
                    <label>Vehicle No</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Vehicle Type</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleType}
                      onChange={(e) => setVehicleType(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Car Model</label>
                    <br />
                    <input
                      type="text"
                      value={carModel}
                      onChange={(e) => setCarModel(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Fuel Type</label>
                    <br />
                    <input
                      type="text"
                      value={fuelType}
                      onChange={(e) => setFuelType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="avatar1">
                  <div className="license-images">
                    <div className="form-group">
                      <label>Business License Image</label>
                      {/* <input
                      type="file"
                      onChange={(e) => setBusinessLicenseImage(e.target.files[0])}
                    /> */}
                      {driverData.DriverInformation.business_license_image && (
                        <img
                          src={
                            driverData.DriverInformation.business_license_image
                              .url
                          }
                          alt="Business License"
                        />
                      )}
                    </div>
                  </div>
                  <div className="license-images flexer">
                    <div>
                      <h6>Vehicle License (front)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .vehicle_license_front_image.url
                        }
                        alt="Vehicle License Front"
                      />
                    </div>
                    <div>
                      <h6>Vehicle License (back)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .vehicle_license_back_image.url
                        }
                        alt="Vehicle License Back"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={handleOkClick} disabled={loading}>
                OK
              </p>
              <p onClick={() => setShowPopup(false)} disabled={loading}>
                Cancel
              </p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>
              Please write down the reject reason
            </p>
            <div className="password">
              <textarea
                required
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={handleReject} disabled={loading}>
                OK
              </p>
              <p onClick={() => setShowSecondPopup(false)} disabled={loading}>
                Cancel
              </p>
            </div>
          </div>
        </div>
      )}
      {showSuccessPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Approve Successful</p>
            </div>
            <div className="successful">
              <p
                onClick={() => {
                  setShowSuccessPopup(false);
                  navigate("/drivers/pending");
                }}
                disabled={loading}
              >
                Ok
              </p>
            </div>
          </div>
        </div>
      )}
      {showUpdatePopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Update Successful</p>
            </div>
            <div className="successful">
              <p
                onClick={() => {
                  setShowUpdatePopup(false);
                  navigate("/drivers/pending");
                }}
                disabled={loading}
              >
                Ok
              </p>
            </div>
          </div>
        </div>
      )}
      {showRejectPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={RejectLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>User was rejected</p>
            </div>
            <div className="successful">
              <p
                onClick={() => {
                  setShowRejectPopup(false);
                  navigate("/drivers/pending");
                }}
                disabled={loading}
              >
                Ok
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingDriverDetails;
