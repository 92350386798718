import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./ConfigDetails.css";

const TopUpDetail = ({ user, setUser }) => {
  const { id } = useParams();
  const location = useLocation();
  const [demand, setDemand] = useState(location.state?.demand || {});
  const [name, setName] = useState(demand.name || "");
  const [fee, setFee] = useState(demand.topup_amount || "");
  useEffect(() => {
    if (!location.state?.demand) {
      const fetchDemand = async () => {
        try {
          const response = await axiosInstanceGet.get(`/topup/${id}`);
          if (response.status === 200) {
            const data = response.data.data;
            setDemand(data);
            setName(data.topup_channel || "");
            setFee(data.topup_amount || "");
          } else {
            console.error(
              "Failed to fetch demand:",
              response.status,
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error fetching demand:", error);
        }
      };

      fetchDemand();
    }
  }, [id, location.state]);

  return (
    <div className="config-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Top-up History</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="form-container">
          <h2 className="section-title">Top-up History &gt; Top-up Detail</h2>
          <div className="config-edit">
            <h2 className="section-title">Filter Driver</h2>
            <div className="form-group-config">
              <div className="config-option">
                <label>Name</label>
                <input
                  type="text"
                  value={demand.tbl_Driver?.name}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
              <div className="config-option">
                <label>phone</label>
                <input
                  type="text"
                  value={demand.tbl_Driver?.phone}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
              <div className="config-option">
                <label>Current Wallet Balance</label>
                <input
                  type="text"
                  value={demand.tbl_Driver?.current_balance}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group-config">
              <div className="config-option">
                <label>Top Up Amount</label>
                <input
                  type="text"
                  value={demand.topup_amount}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
              <div className="config-option">
                <label>Top Up Channel</label>
                <input
                  type="text"
                  value={demand.topup_channel}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
              <div className="config-option">
                <label>Top Up Date</label>
                <input
                  type="text"
                  value={demand.tbl_Driver?.created_date}
                  onChange={(e) => setName(e.target.value)}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group-config">
              <div className="config-option" style={{width: "380px"}}>
                <label></label>
                {demand.screenshot_image && (
                    <img
                      src={demand.screenshot_image.url}
                      alt="Screenshot"
                      className="screenshot-image"
                      style={{width: "350px"}}
                    />
                  )}                  
                <label></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpDetail;
