import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance } from "./axiosInstance";
import "./ConfigDetails.css";
import TopupLogo from "../images/success.png";

const DemandCreate = ({ user, setUser }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [fee, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [password, setPassword] = useState("");

  const handleCreate = async () => {
    setLoading(true);
    try {
      const payload = {
        name,
        fee: parseFloat(fee),
      };

      const response = await axiosInstance.post(`/extra-demands`, payload);

      if (response.status === 201) {
        setShowSecondPopup(true);
      } else {
        console.error(
          "Failed to create demand:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error creating demand:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleCreateClick = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        const token = response.data.data.jwtToken;
        localStorage.setItem('authToken', token);
        setUser({ ...user, token });
        handleCreate();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/extra-demand`);
  };

  return (
    <div className="config-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Create Demand</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="form-container">
          <h2 className="section-title">Additional Charges List &gt; Create New</h2>
          <div className="config-edit">
            <h2 className="section-title">Create New Form</h2>
            <div className="form-group-config">
              <div className="config-option">
                <label>Charges Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter demand name"
                />
              </div>
              <div className="config-option">
                <input
                  type="number"
                  value={fee}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Amount"
                />
              </div>
            </div>
            <div className="button-update">
              <button
                className="update-button"
                onClick={handleCreateClick}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onClose}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3"/>
            <div className="password">
              <p>Creation Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemandCreate;
