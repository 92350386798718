import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";

const CustomerList = ({ user, setUser }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [customers, setCustomers] = useState([]);
  const [activeTab, setActiveTab] = useState("Active");
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabLoading, setTabLoading] = useState(false); // New state for tab loading

  const [nameFilter, setNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [registerFromFilter, setRegisterFromFilter] = useState("");
  const [registerToFilter, setRegisterToFilter] = useState("");
  const [townshipFilter, setTownshipFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  const navigate = useNavigate();

  const fetchCustomers = async () => {
    setLoading(true);
    setTabLoading(true); // Set tabLoading to true
    try {
      const formattedRegisterFrom = registerFromFilter
        ? new Date(registerFromFilter).toISOString()
        : undefined;
      const formattedRegisterTo = registerToFilter
        ? new Date(registerToFilter).toISOString()
        : undefined;

      const response = await axiosInstanceGet.get("/admin/users", {
        params: {
          name: nameFilter || undefined,
          phone: phoneFilter,
          gender: genderFilter || undefined,
          register_start_date: formattedRegisterFrom,
          register_end_date: formattedRegisterTo,
          status: activeTab || undefined,
        },
      });

      setCustomers(response.data.data);
    } catch (error) {
      setError("Failed to fetch customer data");
    } finally {
      setLoading(false);
      setTabLoading(false); // Set tabLoading to false
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setNameFilter(value);
        break;
      case "phone":
        setPhoneFilter(value);
        break;
      case "gender":
        setGenderFilter(value);
        break;
      case "registerFrom":
        setRegisterFromFilter(value);
        break;
      case "registerTo":
        setRegisterToFilter(value);
        break;
      case "township":
        setTownshipFilter(value);
        break;
      case "city":
        setCityFilter(value);
        break;
      default:
        break;
    }
  };

  const handleResultClick = () => {
    // fetchCustomers();
  };

  const handleExportClick = () => {
    setNameFilter("");
    setPhoneFilter("");
    setGenderFilter("");
    setRegisterFromFilter("");
    setRegisterToFilter("");
    setTownshipFilter("");
    setCityFilter("");

    // fetchCustomers();
  };

  const handleTabChange = (tab) => {
    if (tabLoading) return; // Prevent tab change if loading

    setActiveTab(tab);
    // fetchCustomers();
  };

  useEffect(() => {
    fetchCustomers();
  }, [activeTab]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const viewDetails = (customer) => {
    navigate(`/customerdetails/${customer.id}`, { state: { customer } });
  };

  if (error) {
    return <div>{error}</div>;
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRides = customers.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(customers.length / rowsPerPage);

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Customer List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="header">
          <div className="title-container">
            <div className="tabs">
              <button
                className={`tab-button ${
                  activeTab === "Active" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Active")}
                disabled={tabLoading} // Disable tab button if loading
              >
                Active
              </button>
              <button
                className={`tab-button ${
                  activeTab === "Suspended" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Suspended")}
                disabled={tabLoading} // Disable tab button if loading
              >
                Suspended Customers
              </button>
            </div>
          </div>
          <div className="filter-container">
            <button className="filter-button" onClick={toggleFilter}>
              <span data-feather="filter"></span> Filter
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Customer Filter</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={nameFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={phoneFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Gender</label>
              <select
                name="gender"
                value={genderFilter}
                onChange={handleFilterChange}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="filter-option">
              <label>Register From</label>
              <input
                type="date"
                name="registerFrom"
                value={registerFromFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Register To</label>
              <input
                type="date"
                name="registerTo"
                value={registerToFilter}
                onChange={handleFilterChange}
              />
            </div>
            {/* <div className="filter-option">
              <label>Township</label>
              <select
                name="township"
                value={townshipFilter}
                onChange={handleFilterChange}
              >
                <option value="">Select Township</option>
              </select>
            </div>
            <div className="filter-option">
              <label>City</label>
              <select
                name="city"
                value={cityFilter}
                onChange={handleFilterChange}
              >
                <option value="">Select City</option>
              </select>
            </div> */}
            <div className="filter-buttons">
              <button className="export-button" onClick={handleExportClick}>
                Export
              </button>
              <button className="result-button" onClick={handleResultClick}>
                Result
              </button>
            </div>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <table className="customer-table">
            <thead>
              <tr>
                <th className="start">No</th>
                <th>Customer</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Gender</th>
                <th>Address</th>
                <th>
                  Registered <br /> Datetime
                </th>
                <th className="end">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentRides.map((customer, index) => (
                <tr key={customer.id}>
                  <td className="start">{indexOfFirstRow + index + 1}</td>
                  <td>{customer.name}</td>
                  <td>{customer.phone}</td>
                  <td>{customer.email}</td>
                  <td>{customer.gender}</td>
                  <td>{customer.address}</td>
                  <td>{new Date(customer.created_date).toLocaleString()}</td>
                  <td className="end">
                    <button
                      className="detail-button"
                      onClick={() => viewDetails(customer)}
                    >
                      Detail
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {currentPage - 1}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage)}
                  disabled={currentPage === 2}
                >
                  {currentPage}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages - 1}
                >
                  ...
                </button>
              )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
