import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { configNames } from "./ConfigName";
import { axiosInstanceGet } from "./axiosInstance";
import "./ConfigDetails.css";

const ChargesAmount = ({ user, setUser }) => {
  const [configurations, setConfigurations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axiosInstanceGet.get("/dashboard-config");
        if (response.status === 200) {
          setConfigurations(response.data.data.result);
        } else {
          console.error(
            "Failed to fetch configurations:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigurations();
  }, []);

  const viewDetails = (config, feeType) => {
    navigate(`/configdetails/${config.id}/${feeType}`, {
      state: { config, feeType },
    });
  };

  const renderRow = (config, feeType, fee, name) => (
    <tr key={`${config.id}-${feeType}`}>
      <td className="start">{name}</td>
      <td>{fee.price}</td>
      <td>{new Date(fee.created_at).toLocaleString()}</td>
      <td className="end">
        <button
          className="edit-button"
          onClick={() => viewDetails(config, feeType)}
        >
          Edit
        </button>
      </td>
    </tr>
  );

  return (
    <div>
      <table className="config-table">
        <thead>
          <tr>
            <th className="start">Config Name</th>
            <th>Amount</th>
            <th>Created Datetime</th>
            <th className="end">Action</th>
          </tr>
        </thead>
        <tbody>
          {configurations.map((config) => (
            Object.keys(configNames).map((key) =>
              renderRow(config, key, config[key], configNames[key])
            )
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChargesAmount;
