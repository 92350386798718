import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import CustomerList from './components/CustomerList';
import CustomerDetails from './components/CustomerDetails';
import OrderDetail from './components/OrderDetail';
import DriverTopUp from './components/DriverTopup';
import DriverList from './components/DriverList';
import PendingDriverList from './components/PendingDriverList';
import PendingDriverDetails from './components/PendingDriverDetail';
import DriverDetails from './components/DriverDetails';
import Configuration from './components/Configuration';
import ConfigDetails from './components/ConfigDetails';
import MatchingDetail from "./components/MatchingDetail";
import ReferralDetail from './components/ReferralDetail';
import ExtraDemand from './components/ExtraDemand';
import DemandDetail from './components/DemandDetail';
import DemandCreate from './components/DemandCreate';
import UserList from './components/UserList';
import UserCreate from './components/UserCreate';
import InstanceRide from './components/InstanceRide';
import PrivateRoute from './components/PrivateRoute';
import InstanceDetails from './components/InstanceDetail';
import TopUpHistory from './components/TopUpHistory';
import TopUpDetail from './components/TopUpDetails';
import ForgotPassword from './components/ForgotPassword';
import ForgetPassword from './components/LoginForgetPassword';
import InAppOrders from './components/InAppOrders';
import InAppDetails from './components/InAppDetails';
import OnCallOrders from './components/OnCallOrders';
import AllOrders from './components/AllOrders';
import OnCallCreate from './components/OnCallCreate';
import AllOrderDetails from './components/AllOrdersDetail';
import OnCallEdit from './components/OnCallEdit';
import OnCallDetails from './components/OnCallDetails';
import './App.css';

const App = () => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    if (!user) {
      const savedUser = localStorage.getItem('user');
      if (savedUser) {
        setUser(JSON.parse(savedUser));
      }
    }
  }, [user]);

  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <Routes>
            <Route path="/" element={<Login user={user} setUser={setUser} />} />
            <Route path="/login" element={<Login user={user} setUser={setUser} />} />
            <Route path="/forget-password" element={<ForgetPassword user={user} setUser={setUser} />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard user={user} setUser={setUser} />} user={user} />} />
            <Route path="/customerlist" element={<PrivateRoute element={<CustomerList user={user} setUser={setUser} />} user={user} />} />
            <Route path="/driverlist" element={<PrivateRoute element={<DriverList user={user} setUser={setUser} />} user={user} />} />
            <Route path="/drivers/pending" element={<PrivateRoute element={<PendingDriverList user={user} setUser={setUser} />} user={user} />} />
            <Route path="/driverdetails/pending/:id" element={<PrivateRoute element={<PendingDriverDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/customerdetails/:id" element={<PrivateRoute element={<CustomerDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/driverdetails/:id" element={<PrivateRoute element={<DriverDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/order/:id" element={<PrivateRoute element={<OrderDetail user={user} setUser={setUser} />} user={user} />} />
            <Route path="/drivertopup" element={<PrivateRoute element={<DriverTopUp user={user} setUser={setUser} />} user={user} />} />
            <Route path="/drivertopup/history" element={<PrivateRoute element={<TopUpHistory user={user} setUser={setUser} />} user={user} />} />
            <Route path="/drivertopup/details/:id" element={<PrivateRoute element={<TopUpDetail user={user} setUser={setUser} />} user={user} />} />
            <Route path="/configuration" element={<PrivateRoute element={<Configuration user={user} />} user={user} />} />
            <Route path="/configdetails/:id/:feeType" element={<PrivateRoute element={<ConfigDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/matchingdetails/:id" element={<PrivateRoute element={<MatchingDetail user={user} setUser={setUser} />} user={user} />} />
            <Route path="/referraldetails/:id" element={<PrivateRoute element={<ReferralDetail user={user} setUser={setUser} />} user={user} />} />
            <Route path="/extra-demand" element={<PrivateRoute element={<ExtraDemand user={user} setUser={setUser} />} user={user} />} />
            <Route path="/demanddetails/:id" element={<PrivateRoute element={<DemandDetail user={user} setUser={setUser} />} user={user} />} />
            <Route path="/demandcreate" element={<PrivateRoute element={<DemandCreate user={user} setUser={setUser} />} user={user} />} />
            <Route path="/users" element={<PrivateRoute element={<UserList user={user} setUser={setUser} />} user={user} />} />
            <Route path="/user/create" element={<PrivateRoute element={<UserCreate user={user} setUser={setUser} />} user={user} />} />            
            <Route path="/users/forget-password" element={<PrivateRoute element={<ForgotPassword user={user} setUser={setUser} />} user={user} />} /> 
            <Route path="/instance-ride" element={<PrivateRoute element={<InstanceRide user={user} setUser={setUser} />} user={user} />} /> 
            <Route path="/orders/in-app" element={<PrivateRoute element={<InAppOrders user={user} setUser={setUser} />} user={user} />} />
            <Route path="/orders/on-call" element={<PrivateRoute element={<OnCallOrders user={user} setUser={setUser} />} user={user} />} />
            <Route path="/orders/on-call/create" element={<PrivateRoute element={<OnCallCreate user={user} setUser={setUser} />} user={user} />} />
            <Route path="/orders/on-call/edit/:id" element={<PrivateRoute element={<OnCallEdit user={user} setUser={setUser} />} user={user} />} />
            <Route path="/orders/on-call/detail/:id" element={<PrivateRoute element={<OnCallDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/orders/all" element={<PrivateRoute element={<AllOrders user={user} setUser={setUser} />} user={user} />} />
            <Route path="/all-order-details/:id" element={<PrivateRoute element={<AllOrderDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/in-app-details/:id" element={<PrivateRoute element={<InAppDetails user={user} setUser={setUser} />} user={user} />} />
            <Route path="/instance-details/:id" element={<PrivateRoute element={<InstanceDetails user={user} setUser={setUser} />} user={user} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
