import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";
import TopupLogo from "../images/success.png";

const UserList = ({ user, setUser }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showSecondPopup, setShowSecondPopup] = useState(false);

  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstanceGet.get("/admins");
        const fetchedUsers = response.data.data;
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const applyFilters = async () => {
    try { 
      const formattedRegisterFrom = filterFromDate
      ? new Date(filterFromDate).toISOString()
      : undefined;
    const formattedRegisterTo = filterToDate
      ? new Date(filterToDate).toISOString()
      : undefined;
      const response = await axiosInstanceGet.get("/admins", {
        params: {
          name: filterName,
          phone: filterPhone,
          email: filterEmail || undefined,
          register_start_date: formattedRegisterFrom,
          register_end_date: formattedRegisterTo,
        }
      });
      const filtered = response.data.data;
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  const resetFilters = () => {
    setFilterName("");
    setFilterPhone("");
    setFilterEmail("");
    setFilterFromDate("");
    setFilterToDate("");
    applyFilters();
  };

  const viewDetails = (user) => {
    navigate(`/userdetails/${user.id}`, { state: { user } });
  };

  const createNew = () => {
    navigate(`/user/create`);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(`/admins/${selectedUser.id}`);

      if (response.status === 200) {
        setShowSecondPopup(true);
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== selectedUser.id)
        );
        setFilteredUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== selectedUser.id)
        );
      } else {
        console.error(
          "Failed to delete user:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
      setShowDeletePopup(false);
    }
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeletePopup(true);
  };

  const onCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteConfirm = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        handleDelete();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate pagination data
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRides = filteredUsers.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const renderRow = (user, index) => (
    <tr key={user.id}>
      <td className="start">{index + 1}</td>
      <td>{user.name}</td>
      <td>{user.phone}</td>
      <td>{user.email || "-"}</td>
      <td>{new Date(user.creaetd_date).toLocaleString()}</td>
      <td className="end">
        <div style={{whiteSpace: "nowrap"}}>
          <button className="edit-button" onClick={() => viewDetails(user)}>
            Edit
          </button>
          <button
            className="delete-button"
            onClick={() => handleDeleteClick(user)}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">User List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <h2 className="section-title1">User List</h2>
          <div className="button-update">
            <div className="filter-container">
              <button className="filter-button" onClick={toggleFilter}>
                <span data-feather="filter"></span> Filter
              </button>
            </div>
            <button className="update-button" onClick={createNew}>
              + Create User
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>User Filter</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                value={filterPhone}
                onChange={(e) => setFilterPhone(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Email</label>
              <input
                type="text"
                value={filterEmail}
                onChange={(e) => setFilterEmail(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Register From</label>
              <input
                type="date"
                value={filterFromDate}
                onChange={(e) => setFilterFromDate(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Register To</label>
              <input
                type="date"
                value={filterToDate}
                onChange={(e) => setFilterToDate(e.target.value)}
              />
            </div>
            <div className="filter-buttons">
              <button className="export-button" onClick={resetFilters}>
                Export
              </button>
              <button className="result-button" onClick={applyFilters}>
                Result
              </button>
            </div>
          </div>
        )}
        <table className="user-table">
          <thead>
            <tr>
              <th className="start">No</th>
              <th style={{whiteSpace: "nowrap"}}>User Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Created Datetime</th>
              <th className="end">Action</th>
            </tr>
          </thead>
          <tbody>{currentRides.map((user, index) => renderRow(user, index))}</tbody>
        </table>
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {currentPage - 1}
              </button>
            )}

            {totalPages > 2 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(currentPage)}
                disabled={currentPage === 2}
              >
                {currentPage}
              </button>
            )}

            {totalPages > 2 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages - 1}
              >
                ...
              </button>
            )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onCloseDeletePopup}>Cancel</p>
              <p onClick={handleDeleteConfirm}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Delete Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
