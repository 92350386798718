import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";

const InstanceRide = ({ user, setUser }) => {
  const [rides, setRides] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterTypes, setFilterTypes] = useState("INSTANT_RIDE");
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");
  const navigate = useNavigate();

  const fetchRides = async () => {
    try {
      const formattedRegisterFrom = filterFromDate
        ? new Date(filterFromDate).toISOString()
        : undefined;
      const formattedRegisterTo = filterToDate
        ? new Date(filterToDate).toISOString()
        : undefined;
      const response = await axiosInstanceGet.get("/instant-ride?size=200", {
        params: {
          driver_name: filterName || undefined,
          driver_phone: filterPhone || undefined,
          ride_type: filterTypes,
          register_start_date: formattedRegisterFrom,
          register_end_date: formattedRegisterTo,
        },
      });
      setRides(response.data.data);
    } catch (error) {
      console.error("Error fetching rides:", error);
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "filterName":
        setFilterName(value);
        break;
      case "filterPhone":
        setFilterPhone(value);
        break;
      case "filterTypes":
        setFilterTypes(value);
        break;
      case "filterFromDate":
        setFilterFromDate(value);
        break;
      case "filterToDate":
        setFilterToDate(value);
        break;
      default:
        break;
    }
  };

  const resetFilters = () => {
    setFilterName("");
    setFilterPhone("");
    setFilterTypes("INSTANT_RIDE");
    setFilterFromDate("");
    setFilterToDate("");
    fetchRides();
  };

  const viewDetails = (ride) => {
    navigate(`/instance-details/${ride.id}`, { state: { ride } });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchRides();
  }, []);

  // Calculate pagination data
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRides = rides.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(rides.length / rowsPerPage);

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Instance Ride Order List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="header">
          <div className="title-container">
            <h2 className="section-title">All Completed Orders</h2>
          </div>
          <div className="filter-container">
            <button className="filter-button" onClick={toggleFilter}>
              <span data-feather="filter"></span> Filter
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Order Filters</h5>
            <div className="filter-option">
              <label>Driver Name</label>
              <input
                type="text"
                name="filterName"
                value={filterName}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Driver Phone</label>
              <input
                type="text"
                name="filterPhone"
                value={filterPhone}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Status</label>
              <select
                name="filterTypes"
                value={filterTypes}
                onChange={handleFilterChange}
              >
                <option value="INSTANT_RIDE">Instance Ride</option>
                <option value="MATCHMAKING">Match Making</option>
              </select>
            </div>
            <div className="filter-option">
              <label>Register From</label>
              <input
                type="date"
                name="filterFromDate"
                value={filterFromDate}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Register To</label>
              <input
                type="date"
                name="filterToDate"
                value={filterToDate}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-buttons">
              <button className="export-button" onClick={resetFilters}>
                Export
              </button>
              <button className="result-button" onClick={fetchRides}>
                Result
              </button>
            </div>
          </div>
        )}
        <table className="instance-table">
          <thead>
            <tr>
              <th className="start">Order ID</th>
              <th>Customer</th>
              <th>Driver</th>
              <th>From</th>
              <th>To</th>
              <th>Type</th>
              <th>Request Datetime</th>
              <th className="end">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentRides.map((ride, index) => (
              <tr key={ride.id}>
                <td className="start">#{indexOfFirstRow + ride.id}</td>
                <td>
                  {ride.tbl_User ? (
                    <>
                      {ride.tbl_User.name} <br />
                      {ride.tbl_User.phone}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {ride.tbl_Driver ? (
                    <>
                      {ride.tbl_Driver.name} <br />
                      {ride.tbl_Driver.phone}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{ride.start_point.name}</td>
                <td>{ride.end_point.name}</td>
                <td>{ride.ride_type || "-"}</td>
                <td>{new Date(ride.created_date).toLocaleString()}</td>
                <td className="end">
                  <button
                    className="edit-button"
                    onClick={() => viewDetails(ride)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {currentPage - 1}
              </button>
            )}

            {totalPages > 2 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(currentPage)}
                disabled={currentPage === 2}
              >
                {currentPage}
              </button>
            )}

            {totalPages > 2 && currentPage !== totalPages - 1 && currentPage !== totalPages && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages - 1}
              >
                ...
              </button>
            )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstanceRide;
