import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import PopUpBox from "./PopUpBox";
import "./DriverTopup.css";
import Undo from "../images/undo.png";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import TopupLogo from "../images/success.png";

const DriverTopUp = ({ user, setUser }) => {
  const [driverPhone, setDriverPhone] = useState("");
  const [driverId, setDriverId] = useState("");
  const [driverName, setDriverName] = useState("");
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [topUpAmount, setTopUpAmount] = useState(0);
  const [topUpChannel, setTopUpChannel] = useState("Kpay");
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleCheck = async () => {
    try {
      const response = await axiosInstanceGet.get("/drivers", {
        params: {
          phone: driverPhone || undefined,
          id: Number(driverId) || undefined,
        },
      });

      const driverDataArray = response.data.data;

      if (driverDataArray.length > 0) {
        const driverData = driverDataArray[0];

        setDriverName(driverData.name || "-");
        setCurrentWalletBalance(driverData.current_balance || 0);
        setDriverId(driverData.id || "");
      } else {
        setPopupMessage("No driver data found.");
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
      setPopupMessage("Failed to fetch driver data.");
    }
  };

  const handleScreenshotUpload = (e) => {
    const file = e.target.files[0];
    setScreenshot(file);
    setScreenshotUrl(URL.createObjectURL(file));
    document
      .querySelector(".custom-file-upload-button")
      .classList.add("hidden");
    document.querySelector(".screenshot").classList.add("image");
  };

  const handleRemoveScreenshot = () => {
    setScreenshot(null);
    setScreenshotUrl("");
    document
      .querySelector(".custom-file-upload-button")
      .classList.remove("hidden");
    document.querySelector(".screenshot").classList.remove("image");
  };

  const handleSubmit = async (screenshotId) => {
    try {
      const payload = {
        driver_id: Number(driverId),
        topup_channel: topUpChannel,
        topup_amount: topUpAmount,
        screenshot_id: screenshotId,
      };

      const response = await axiosInstance.post("/topup", payload);

      setPopupMessage(response.data.message);
      setShowPopup(false);
      setShowSecondPopup(true);
    } catch (error) {
      console.error("Error:", error);
      setPopupMessage("Failed to submit top-up request.");
    }
  };

  const handleUpdateClick = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        let screenshotId = null;
        if (screenshot) {
          const formData = new FormData();
          formData.append("photo", screenshot);
          formData.append("type", "driver_profile_image");

          const uploadResponse = await axiosInstance.post(
            "/photo-upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          screenshotId = uploadResponse.data.data.id;
        }

        handleSubmit(screenshotId);
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/drivertopup`);
  };

  return (
    <div className="driver-topup-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div style={{ marginLeft: "10%" }} className="title-container">
            <h1 className="driver-topup-title">Top-up to Wallet</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="header">
          <div style={{ marginLeft: "10%" }} className="title-container">
            <h1 className="driver-topup-title">Manual Top-up Form</h1>
          </div>
        </div>
        <div className="topup-form">
          <h2>Top-up Form</h2>
          <div className="form-groupi">
            <div className="input-container">
              <input
                type="text"
                value={driverPhone}
                onChange={(e) => setDriverPhone(e.target.value)}
              />
              <label>Driver Phone</label>
            </div>
            <span>Or</span>
            <div className="input-container">
              <input
                type="text"
                value={driverId}
                onChange={(e) => setDriverId(e.target.value)}
              />
              <label>Driver ID</label>
            </div>
            <div className="input-container">
              <button
                type="button"
                className="check-button"
                onClick={handleCheck}
              >
                Check
              </button>
            </div>
          </div>
          <hr />
          <div className="form-groupi">
            <div className="input-container">
              <input type="text" value={driverName} readOnly required />
              <label>Driver Name</label>
            </div>
            <div className="input-container">
              <input
                type="number"
                value={currentWalletBalance}
                readOnly
                required
              />
              <label>Current Wallet Balance</label>
            </div>
          </div>
          <div className="form-group1">
            <div className="input-container">
              <input
                type="number"
                value={topUpAmount}
                onChange={(e) =>
                  setTopUpAmount(parseFloat(e.target.value) || "")
                }
                required
              />
              <label>Top-up Amount</label>
            </div>
          </div>
          <div className="form-group1">
            <div className="input-container">
              <select
                value={topUpChannel}
                onChange={(e) => setTopUpChannel(e.target.value)}
                required
              >
                <option value="Kpay">K Pay</option>
                <option value="Citizen_Pay">Citizen Pay</option>
                <option value="Manual_Top_Up">Manual Top Up</option>
              </select>
              <label>Top-up With</label>
            </div>
          </div>
          <div className="form-group1">
            <div className="screenshot">
              <label>Screenshot</label>
              {screenshotUrl && (
                <img
                  src={screenshotUrl}
                  alt="Screenshot Preview"
                  className="screenshot-preview"
                />
              )}
              {screenshotUrl && (
                <img
                  src={Undo}
                  alt="Undo"
                  className="Undo"
                  onClick={handleRemoveScreenshot}
                />
              )}
              <input
                type="file"
                id="screenshotInput"
                onChange={handleScreenshotUpload}
                required
              />
              <button
                type="button"
                className="custom-file-upload-button"
                onClick={() =>
                  document.getElementById("screenshotInput").click()
                }
              >
                Click to Upload
              </button>
            </div>
          </div>
          <div className="button-topup">
            <button
              type="submit"
              className="update-button"
              onClick={handleUpdateClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onClose}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Top Up Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverTopUp;
