import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet, axiosInstance } from "./axiosInstance";
import "./CustomerDetails.css";
import TopupLogo from "../images/suspend.png";

const CustomerDetails = ({ user, setUser }) => {
  const [activeTab, setActiveTab] = useState("Customer Information");
  const [filter, setFilter] = useState({
    from: "2024-08-01",
    to: "2025-12-27",
    status: "All",
  });
  const [customer, setCustomer] = useState({});
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [action, setAction] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [pagination, setPagination] = useState({
    totalCount: 0,
    perPage: 10,
    currentPage: 1,
    foundCount: 0
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await axiosInstanceGet.get(`/admin/users/${id}`);
        setCustomer(response.data.data || {});
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch customer details");
        setLoading(false);
      }
    };

    const fetchCustomerHistory = async (page = 1) => {
      try {
        const response = await axiosInstanceGet.get(`/admin/users/history/${id}`, {
          params: {
            page,
            perPage: pagination.perPage
          }
        });
        setOrders(response.data.data.rides || []);
        setPagination({
          totalCount: response.data.meta.totalCount,
          perPage: response.data.meta.perPage,
          currentPage: response.data.meta.currentPage,
          foundCount: response.data.meta.foundCount
        });
      } catch (error) {
        setError("Failed to fetch customer history");
      }
    };

    fetchCustomerDetails();
    fetchCustomerHistory(pagination.currentPage);
  }, [id, pagination.currentPage]);

  const filteredOrders = orders.filter((order) => {
    const orderDate = new Date(order.created_date);
    const fromDate = new Date(filter.from);
    const toDate = new Date(filter.to);
    const statusMatch =
      filter.status === "All" || order.status === filter.status;
    return orderDate >= fromDate && orderDate <= toDate && statusMatch;
  });

  const handleApproveClick = () => {
    setAction("approve");
    setShowPopup(true);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      console.error("User is not logged in or email is not available.");
      return;
    }

    try {
      const loginResponse = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });

      if (loginResponse.status === 201) {
        await handleApprove();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleApprove = async () => {
    try {
      const response = await axiosInstance.patch(`/admin/users/suspend/${id}`, { password });

      if (response.status === 200) {
        setShowSuccessPopup(true);
      } else {
        console.error("Failed to suspend user:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error suspending user:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const viewDetails = (order) => {
    navigate(`/order/${order.id}`, { state: { order, customer } });
  };

  const handlePageChange = (pageNumber) => {
    setPagination(prev => ({
      ...prev,
      currentPage: pageNumber
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!customer || !Object.keys(customer).length) {
    return <div>No customer data available</div>;
  }

  return (
    <div className="customer-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-details-title">Customer Details</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar"></div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="customer-details">
          <div className="customer-info">
            <div className="info-header1">
              <h4>{customer.name || "N/A"}</h4>
              <button
                className={`status-button suspend`}
                onClick={handleApproveClick}
                disabled={loading}
              >
                Suspend
              </button>
            </div>
            <div className="card-container1">
              <div className="card">
                <span>{orders.length || 0}</span>
                <h5>All Orders</h5>
              </div>
              <div className="card">
                <span>
                  {orders.filter((order) => order.status === "COMPLETED")
                    .length || 0}
                </span>
                <h5>Completed Orders</h5>
              </div>
              <div className="card">
                <span>
                  {orders.filter((order) => order.status === "CANCELED")
                    .length || 0}
                </span>
                <h5>Cancelled Orders</h5>
              </div>
            </div>
          </div>
          <div className="tab-container">
            <div
              className={`tab ${
                activeTab === "Customer Information" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Customer Information")}
            >
              Customer Information
            </div>
            <div
              className={`tab ${
                activeTab === "Customer History" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Customer History")}
            >
              Customer History
            </div>
          </div>
          {activeTab === "Customer Information" && (
            <div className="tab-content">
              <h3>Customer Information Details</h3>
              <div className="customer-info2">
                <div className="avatar">
                  <div className="circle"></div>
                </div>
                <div className="details">
                  <p>
                    Name:
                    <br /> <strong>{customer.name || "N/A"}</strong>{" "}
                  </p>
                  <p>
                    Phone:
                    <br /> <strong>{customer.phone || "N/A"}</strong>
                  </p>
                  <p>
                    Email:
                    <br /> <strong>{customer.email || "N/A"}</strong>
                  </p>
                  <p>
                    Gender:
                    <br /> <strong>{customer.gender || "N/A"}</strong>
                  </p>
                  <p>
                    Created Date:
                    <br />{" "}
                    <strong>
                      {customer.created_date
                        ? new Date(customer.created_date).toLocaleDateString()
                        : "N/A"}
                    </strong>
                  </p>
                  <p>
                    Updated Date:
                    <br />{" "}
                    <strong>
                      {customer.updated_date
                        ? new Date(customer.updated_date).toLocaleDateString()
                        : "N/A"}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Customer History" && (
            <div className="tab-content">
              <div className="filter-container">
                <div className="filter-option">
                  <label>From</label>
                  <input
                    type="date"
                    value={filter.from}
                    onChange={(e) =>
                      setFilter({ ...filter, from: e.target.value })
                    }
                  />
                </div>
                <div className="filter-option">
                  <label>To</label>
                  <input
                    type="date"
                    value={filter.to}
                    onChange={(e) =>
                      setFilter({ ...filter, to: e.target.value })
                    }
                  />
                </div>
                <div className="filter-option">
                  <label>Status</label>
                  <select
                    value={filter.status}
                    onChange={(e) =>
                      setFilter({ ...filter, status: e.target.value })
                    }
                  >
                    <option value="All">All</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="CANCELED">Cancelled</option>
                    {/* Add more options if needed */}
                  </select>
                </div>
              </div>
              <table className="order-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Route</th>
                    <th>Status</th>
                    <th>Total Amount</th>
                    <th>Date Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.length ? (
                    filteredOrders.map((order, index) => (
                      <tr key={order.id}>
                        <td>#{order.id + (pagination.currentPage - 1) * pagination.perPage}</td>
                        <td>{`${order.start_point?.name || "N/A"} to ${
                          order.end_point?.name || "N/A"
                        }`}</td>
                        <td className={`status-${order.status?.toLowerCase() || "unknown"}`}>
                          {order.status || "N/A"}
                        </td>
                        <td>{order.total_price || "N/A"} MMK</td>
                        <td>
                          {order.created_date
                            ? new Date(order.created_date).toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          <button
                            className="detail-button"
                            onClick={() => viewDetails(order)}
                          >
                            Detail
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No orders available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                >
                  &lt;
                </button>
                <span>Page {pagination.currentPage} of {Math.ceil(pagination.totalCount / pagination.perPage)}</span>
                <button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={pagination.currentPage * pagination.perPage >= pagination.totalCount}
                >
                  &gt;
                </button>
              </div>
            </div>
          )}
        </div>
        {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
            <p onClick={() => setShowPopup(false)}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSuccessPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3"/>
            <br />
            <div className="password">
              <p>User Suspended Successfully</p>
            </div>
            <div className="successful">
            <p onClick={() => setShowSuccessPopup(false)}>OK</p>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default CustomerDetails;
