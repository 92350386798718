import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MatchingSetting = ({ user, setUser }) => {
  const [configurations, setConfigurations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await fetch(
          "https://readapi.techhexamyanmar.com/api/dashboard-config/match-making",
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (response.ok) {
          const data = await response.json();
          setConfigurations(data.data);
        } else {
          console.error(
            "Failed to fetch configurations:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigurations();
  }, []);

  const viewDetails = (config) => {
    navigate(`/matchingdetails/${config.id}`, {
      state: { config },
    });
  };

  return (
    <div>
      <table className="match-table">
        <thead>
          <tr>
            <th className="start">Config Name</th>
            <th>Distance</th>
            <th>Created Datetime</th>
            <th className="end">Action</th>
          </tr>
        </thead>
        <tbody>
          {configurations.map((config) => (
            <tr key={config.id}>
              <td className="start">{config.name}</td>
              <td>{config.distance} {config.unit}</td>
              <td>{new Date(config.created_date).toLocaleString()}</td>
              <td className="end">
                <button
                  className="edit-button"
                  onClick={() => viewDetails(config)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchingSetting;
