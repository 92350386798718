import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "./axiosInstance";
import "./ForgetPassword.css";
import loginImage from "../images/login.png";
import logoImage from "../images/logo.png";
import TopupLogo from "../images/success.png";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState("");
  const [confirmNewUserPassword, setConfirmNewUserPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (
      newUserPassword &&
      confirmNewUserPassword &&
      newUserPassword !== confirmNewUserPassword
    ) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError("");
    }
  }, [newUserPassword, confirmNewUserPassword]);

  const handleCreate = async () => {
    if (passwordError) {
      return;
    }

    setLoading(true);
    try {
      const payload = {
        email,
        password: confirmNewUserPassword,
        confirm_password: newUserPassword,
      };

      const response = await axiosInstance.patch(
        `/admins/forgot-password`,
        payload
      );

      if (response.status === 200) {
        setShowSecondPopup(true);
      } else {
        console.error(
          "Failed to change password:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error changing password:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleCreateClick = () => {
    handleCreate();
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/forget-password`);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const Home = () => {
    navigate('/');
  }

  return (
    <div className="forget-password-container">
      <div className="background-image-container">
        <img src={loginImage} alt="Background" className="background-img" />
      </div>
      <div className="forget-password-box">
        <img src={logoImage} onClick={Home} alt="Best Kilo Logo" className="logo-img" />
        <h2 className="title">Forget Password</h2>
        <p className="subtitle">
          Please enter the registered email address and new password to reset your password.
        </p>
        <div className="form-group">
          <input
            type="email"
            className="form-control mb-3"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control mb-3"
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              value={confirmNewUserPassword}
              onChange={(e) => setConfirmNewUserPassword(e.target.value)}
              placeholder="Confirm Password"
            />
          </div>
          {(newUserPassword || confirmNewUserPassword) && (
            <span onClick={togglePasswordVisibility} className="toggle-password-visibility">
              {passwordVisible ? "Hide" : "View"} Passcode
            </span>
          )}
          {passwordError && <div className="password-error">{passwordError}</div>}
        </div>
        <button className="btn btn-submit" onClick={handleCreateClick}>
          Submit
        </button>
      </div>
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>New Password was updated</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;
