import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstanceGet, axiosInstance } from "./axiosInstance";
import "./CustomerDetails.css";
import TopupLogo from "../images/suspend.png";

const DriverDetails = ({ user, setUser }) => {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Driver Information");
  const [driverData, setDriverData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);  
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [state, setState] = useState("");
  const [township, setTownship] = useState("");
  const [address, setAddress] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [carModel, setCarModel] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [businessLicenseImage, setBusinessLicenseImage] = useState(null);
  const [vehicleLicenseFrontImage, setVehicleLicenseFrontImage] =
    useState(null);
  const [vehicleLicenseBackImage, setVehicleLicenseBackImage] = useState(null);

  useEffect(() => {
    const fetchDriverData = async () => {
      try {
        const response = await axiosInstanceGet.get(`/drivers/${id}`);
        setDriverData(response.data.data);
        setState(response.data.data.tbl_state.eng);
        setTownship(response.data.data.tbl_township.eng);
        setAddress(response.data.data.address);
        setVehicleNumber(response.data.data.DriverInformation.vehicle_number);
        setVehicleType(response.data.data.DriverInformation.vehicle_type);
        setCarModel(response.data.data.DriverInformation.car_model);
        setFuelType(response.data.data.DriverInformation.fuel_type);
      } catch (error) {
        console.error("Error fetching driver data:", error);
      }
    };

    fetchDriverData();
  }, []);

  const handleApproveClick = () => {
    setAction("approve");
    setShowPopup(true);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const loginResponse = await axiosInstance.post(
        `/auth/admins/login`,
        {
          email: user.email,
          password,
        }
      );

      if (loginResponse.status === 201) {
        if (action === "update") {
          await handleUpdate();
        } else {
          await handleApprove();
        }
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.patch(
        `/drivers/suspend/${id}`,
        { password }
      );

      if (response.status === 200) {
        setShowSuccessPopup(true);
      } else {
        console.error("Failed to suspend driver:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error suspending driver:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleUpdateClick = () => {
    setAction("update");
    setShowPopup(true);
  };

  const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);
  
    try {
      const response = await axiosInstance.post('/upload/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.imageId; // Assuming the response contains the image ID.
    } catch (error) {
      console.error('Image upload failed:', error);
      return null;
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const businessLicenseId = businessLicenseImage ? await uploadImage(businessLicenseImage) : driverData.DriverInformation.business_license_image.id;
      const vehicleLicenseFrontId = vehicleLicenseFrontImage ? await uploadImage(vehicleLicenseFrontImage) : driverData.DriverInformation.vehicle_license_front_image.id;
      const vehicleLicenseBackId = vehicleLicenseBackImage ? await uploadImage(vehicleLicenseBackImage) : driverData.DriverInformation.vehicle_license_back_image.id;
  
      const payload = {
        city_id: driverData.tbl_state.id,
        township_id: driverData.tbl_township.id,
        address: address,
        vehicle_number: vehicleNumber,
        vehicle_type: vehicleType,
        car_model: carModel,
        fuel_type: fuelType,
        business_license_image_id: businessLicenseId,
        vehicle_license_front_image_id: vehicleLicenseFrontId,
        vehicle_license_back_image_id: vehicleLicenseBackId,
      };
  
      const response = await axiosInstance.patch(`/drivers/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
  
      if (response.status === 200) {
        setShowUpdatePopup(true);
      } else {
        console.error('Failed to update driver:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error updating driver:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  if (!driverData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customer-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-details-title">Driver Details</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="customer-details">
          <div className="customer-info">
          <div className="info-header1" style={{justifyContent: "flex-start"}}>
              <a href="/driverlist">Driver List </a><h4> &gt; Details</h4>
            </div>
          </div>
          <div className="tab-container">
            <div
              className={`tab ${
                activeTab === "Driver Information" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Driver Information")}
            >
              Driver Information
            </div>
            <div
              className={`tab ${
                activeTab === "Vehicle Information" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Vehicle Information")}
            >
              Vehicle Information
            </div>
            <div className="status-tab">
            <button
                className={`status-button edit`}
                onClick={handleUpdateClick}
                disabled={loading}
              >
                Update
              </button>
              <button
                className={`status-button suspend`}
                onClick={handleApproveClick}
                disabled={loading}
              >
                Suspend
              </button>
            </div>
          </div>
          {activeTab === "Driver Information" && (
            <div className="tab-content">
              <h4 className="mb-4">Driver Information</h4>
              <div className="customer-info2">
                <div className="avatar">
                  <img
                    className="circle"
                    src={driverData.DriverInformation.driver_profile_image.url}
                    alt="Driver"
                  />
                </div>
                <div className="details">
                  <p>
                    Name
                    <br /> <strong>{driverData.name}</strong>{" "}
                  </p>
                  <p>
                    Phone
                    <br /> <strong>{driverData.phone}</strong>
                  </p>
                  <p>
                    Driving License No
                    <br /> <strong>{driverData.driving_license}</strong>
                  </p>
                  <div className="license-images flexer">
                    <div>
                      <h6>Driving License (front)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .driving_license_front_image.url
                        }
                        alt="Driving License Front"
                      />
                    </div>
                    <div>
                      <h6>Driving License (back)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .driving_license_back_image.url
                        }
                        alt="Driving License Back"
                      />
                    </div>
                  </div>
                  <p>
                    NRC Number
                    <br />{" "}
                    <strong>
                      {driverData.nrc_township}({driverData.nrc_type})
                      {driverData.nrc_id_number}
                    </strong>
                  </p>
                  <div className="flexer">
                    <div className="form-groups">
                      <label>City</label>
                      <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className="form-groups">
                      <label>Township</label>
                      <input
                        type="text"
                        value={township}
                        onChange={(e) => setTownship(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-groups">
                    <label>Address</label>
                    <br />
                    <textarea
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "Vehicle Information" && (
            <div className="tab-content">
              <h4 className="mb-4">Vehicle Information</h4>
              <div className="customer-info3">
              <div className="details">
                  <div className="form-groups">
                    <label>Vehicle No</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Vehicle Type</label>
                    <br />
                    <input
                      type="text"
                      value={vehicleType}
                      onChange={(e) => setVehicleType(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Car Model</label>
                    <br />
                    <input
                      type="text"
                      value={carModel}
                      onChange={(e) => setCarModel(e.target.value)}
                    />
                  </div>
                  <div className="form-groups">
                    <label>Fuel Type</label>
                    <br />
                    <input
                      type="text"
                      value={fuelType}
                      onChange={(e) => setFuelType(e.target.value)}
                    />
                  </div>
                </div>
                <div className="avatar1">
                  <div className="license-images">
                    <div className="form-group">
                      <label>Business License Image</label>
                      {driverData.DriverInformation.business_license_image && (
                        <img
                          src={
                            driverData.DriverInformation.business_license_image
                              .url
                          }
                          alt="Business License"
                        />
                      )}
                    </div>
                  </div>
                  <div className="license-images flexer">
                    <div>
                      <h6>Vehicle License (front)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .vehicle_license_front_image.url
                        }
                        alt="Vehicle License Front"
                      />
                    </div>
                    <div>
                      <h6>Vehicle License (back)</h6>
                      <img
                        src={
                          driverData.DriverInformation
                            .vehicle_license_back_image.url
                        }
                        alt="Vehicle License Back"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
            <p onClick={handleOkClick} disabled={loading}>
              OK
            </p>
            <p onClick={() => setShowPopup(false)} disabled={loading}>
              Cancel
            </p>
            </div>
          </div>
        </div>
      )}
      {showUpdatePopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Update Successful</p>
            </div>
            <div className="successful">
              <p
                onClick={() => {
                  setShowUpdatePopup(false);
                  navigate("/drivers/pending");
                }}
                disabled={loading}
              >
                Ok
              </p>
            </div>
          </div>
        </div>
      )}
      {showSuccessPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Account Suspended</p>
            </div>
            <div className="successful">
              <p onClick={() => setShowSuccessPopup(false)} disabled={loading}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverDetails;
