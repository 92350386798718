import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import "./ConfigDetails.css";
import TopupLogo from "../images/success.png";
import CashLogo from "../images/cash-text.png";
import { configNames } from "./ConfigName";

const ConfigDetails = ({ user, setUser }) => {
  const { id, feeType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [config, setConfig] = useState(location.state?.config || {});
  const [price, setPrice] = useState(config[feeType]?.price || "");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!location.state?.config) {
      const fetchConfig = async () => {
        try {
          const response = await axiosInstanceGet.get(`/dashboard-config/${id}`);
          if (response.status === 200) {
            const data = response.data;
            setConfig(data);
            setPrice(data[feeType]?.price || "");
          } else {
            console.error(
              "Failed to fetch configuration:",
              response.status,
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error fetching configuration:", error);
        }
      };

      fetchConfig();
    }
  }, [id, feeType, location.state]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updatedConfig = {
        ...config,
        [feeType]: {
          ...config[feeType],
          price: parseFloat(price),
        },
      };

      const response = await axiosInstance.patch(`/dashboard-config/${id}`, updatedConfig);

      if (response.status === 200) {
        setShowSecondPopup(true);
      } else {
        console.error(
          "Failed to update configuration:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error updating configuration:", error);
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleUpdateClick = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  const handleOkClick = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        handleUpdate();
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/configuration`);
  };

  return (
    <div className="config-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Configuration</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="form-container">
          <h2 className="section-title">Config Setting List &gt; Edit</h2>
          <div className="config-edit">
            <div className="input-header">
              <h2 className="section-title">Edit Form</h2>
              <div>
                <img src={CashLogo} alt="Logo" className="cash-logo"/>
              </div>
            </div>
            <div className="form-group-config">
              <div className="config-option">
                <label>Config Name</label>
                <input
                  style={{ cursor: "auto" }}
                  type="text"
                  value={configNames[feeType]}
                  readOnly
                />
              </div>
              <div className="config-option">
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Amount"
                />
              </div>
            </div>
            <div className="button-update">
              <button
                className="update-button"
                onClick={handleUpdateClick}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onClose}>Cancel</p>
              <p onClick={handleOkClick}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3"/>
            <br />
            <div className="password">
              <p>Update Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigDetails;
