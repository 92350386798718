import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance } from "./axiosInstance";
import "./ConfigDetails.css";
import TopupLogo from "../images/success.png";

const ForgotPassword = ({ user, setUser }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState("");
  const [confirmNewUserPassword, setConfirmNewUserPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (
      newUserPassword &&
      confirmNewUserPassword &&
      newUserPassword !== confirmNewUserPassword
    ) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError("");
    }
  }, [newUserPassword, confirmNewUserPassword]);

  const handleCreate = async () => {
    if (passwordError) {
      return;
    }

    setLoading(true);
    try {
      const payload = {
        email,
        password: confirmNewUserPassword,
        confirm_password: newUserPassword,
      };

      const response = await axiosInstance.patch(`/admins/forgot-password`, payload);

      if (response.status === 200) {
        setShowSecondPopup(true);
      } else {
        console.error(
          "Failed to change password:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error changing password:", error);
      console.error("Error changing password.");
    } finally {
      setLoading(false);
      setShowPopup(false);
    }
  };

  const handleCreateClick = () => { 
    handleCreate();
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
    navigate(`/users/forget-password`);
  };

  return (
    <div className="config-details-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Change password</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div className="form-container">
          <div className="forget-password">
            <h2 className="section-title">Change Password</h2>
            <div className="form-group-config">
              <div className="user-create1">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="form-group-config">
              <div className="user-create1">
                <input
                  type="password"
                  value={newUserPassword}
                  onChange={(e) => setNewUserPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
            </div>
            <div className="form-group-config">
              <div className="user-create1">
                <input
                  type="password"
                  value={confirmNewUserPassword}
                  onChange={(e) => setConfirmNewUserPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            {passwordError && (
              <div className="password-error">{passwordError}</div>
            )}
            <div className="button-forget">
              <button
                className="update-button"
                onClick={handleCreateClick}
                disabled={loading || passwordError}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>New Password was updated</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
