import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";

const TopUpHistory = ({ user, setUser }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstanceGet.get("/topup");
        const fetchedUsers = response.data.data;
        setUsers(fetchedUsers);
        setFilteredUsers(fetchedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const applyFilters = async () => {
    setLoading(true);
    try {
      const formattedRegisterFrom = filterFromDate ? new Date(filterFromDate).toISOString() : undefined;
      const formattedRegisterTo = filterToDate ? new Date(filterToDate).toISOString() : undefined;

      const response = await axiosInstanceGet.get("/topup", {
        params: {
          name: filterName,
          phone: filterPhone,
          register_start_date: formattedRegisterFrom,
          register_end_date: formattedRegisterTo,
        },
      });
      setFilteredUsers(response.data.data);
    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetFilters = () => {
    setFilterName("");
    setFilterPhone("");
    setFilterFromDate("");
    setFilterToDate("");
    applyFilters();
  };

  const viewDetails = (user) => {
    navigate(`/drivertopup/details/${user.id}`, { state: { user } });
  };

  const renderRow = (user, index) => (
    <tr key={user.id}>
      <td className="start">{index + 1}</td>
      <td>{user.tbl_Driver.name}</td>
      <td>{user.tbl_Driver.phone}</td>
      <td>{user.topup_amount || "-"}</td>
      <td>{user.topup_channel || "-"}</td>
      <td>{new Date(user.created_date).toLocaleString()}</td>
      <td className="end">
        <button style={{ whiteSpace: "nowrap" }} className="edit-button" onClick={() => viewDetails(user)}>
          View Details
        </button>
      </td>
    </tr>
  );

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">Top-up History List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
          <div className="user-info">
            <div className="user-avatar">{/* avatar */}</div>
            <span className="user-name">{user ? user.name : "Guest"}</span>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <h2 className="section-title1" style={{ whiteSpace: "nowrap" }} >Top-up History List</h2>
          <div className="button-update">
            <div className="filter-container">
              <button className="filter-button" onClick={toggleFilter}>
                <span data-feather="filter"></span> Filter
              </button>
            </div>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Filter Driver</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                value={filterPhone}
                onChange={(e) => setFilterPhone(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Register From</label>
              <input
                type="date"
                value={filterFromDate}
                onChange={(e) => setFilterFromDate(e.target.value)}
              />
            </div>
            <div className="filter-option">
              <label>Register To</label>
              <input
                type="date"
                value={filterToDate}
                onChange={(e) => setFilterToDate(e.target.value)}
              />
            </div>
            <div className="filter-buttons">
              <button className="export-button" onClick={resetFilters}>
                Export
              </button>
              <button className="result-button" onClick={applyFilters}>
                {loading ? "Loading..." : "Result"}
              </button>
            </div>
          </div>
        )}
        <table className="user-table">
          <thead>
            <tr>
              <th className="start">No</th>
              <th>Driver Name</th>
              <th>Phone</th>
              <th>Top-up Amount</th>
              <th>Top-up Channel</th>             
              <th>Datetime</th>
              <th className="end">Action</th>
            </tr>
          </thead>
          <tbody>{filteredUsers.map((user, index) => renderRow(user, index))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TopUpHistory;
