import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { axiosInstance, axiosInstanceGet } from "./axiosInstance";
import "./CustomerList.css";
import TopupLogo from "../images/success.png";

const OnCallOrders = ({ user, setUser }) => {
  const [orders, setOrders] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [canceledOrdersCount, setCanceledOrdersCount] = useState(0);
  const [acceptedOrdersCount, setAcceptedOrdersCount] = useState(0);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [activeTab, setActiveTab] = useState("PENDING");
  const [nameFilter, setNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [tabLoading, setTabLoading] = useState(false);

  const handleDeleteClick = (orderId) => {
    setSelectedUser(orderId);
    setShowDeletePopup(true);
  };

  const onCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteConfirm = async () => {
    if (!user || !user.email) {
      return;
    }

    try {
      const response = await axiosInstance.post(`/auth/admins/login`, {
        email: user.email,
        password,
      });
      if (response.status === 201) {
        handleDelete(selectedUser);
      } else {
        console.error("Invalid password.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleDelete = async (orderId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(
        `/on-call-order?size=200/${orderId}`
      );

      if (response.status === 200) {
        setShowSecondPopup(true);
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== selectedUser.id)
        );
        setFilteredUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== selectedUser.id)
        );
      } else {
        console.error(
          "Failed to delete user:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
      setShowDeletePopup(false);
    }
  };

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
  };

  const fetchOrders = async () => {
    setLoading(true);
    setTabLoading(true); // Set tabLoading to true when fetching data
    try {
      const response = await axiosInstanceGet.get("/on-call-order", {
        params: {
          status: activeTab,
          name: nameFilter || undefined,
          phone: phoneFilter || undefined,
        },
      });

      const fetchedOrders = response.data.data;
      // const declined = await axiosInstanceGet.get(
      //   `/on-call-order?status=CANCELED`
      // );
      // const CanceledDrivers = declined.data.data;
      // const accepted = await axiosInstanceGet.get(
      //   `/on-call-order?status=ACCEPTED`
      // );
      // const acceptedDrivers = accepted.data.data;
      // const completed = await axiosInstanceGet.get(
      //   `/on-call-order?status=COMPLETED`
      // );
      // const completedDrivers = completed.data.data;
      setOrders(fetchedOrders);

      // if (activeTab === "PENDING") {
      //   setPendingOrdersCount(fetchedOrders.length);
      // } else if (activeTab === "CANCELED") {
      //   setCanceledOrdersCount(CanceledDrivers.length);
      // } else if (activeTab === "ACCEPTED") {
      //   setAcceptedOrdersCount(acceptedDrivers.length);
      // } else if (activeTab === "COMPLETED") {
      //   setCompletedOrdersCount(completedDrivers.length);
      // }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
      setTabLoading(false); // Set tabLoading to false when done
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNameFilter(value);
    } else if (name === "phone") {
      setPhoneFilter(value);
    }
  };

  const handleResultClick = () => {
    fetchOrders();
  };

  const handleExportClick = () => {
    setNameFilter("");
    setPhoneFilter("");
    // fetchOrders();
  };

  const handleTabChange = (tab) => {
    if (tabLoading) return;
    setActiveTab(tab);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const viewDetails = (order) => {
    navigate(`/orders/on-call/edit/${order.id}`, { state: { order } });
  };

  const viewDetail = (order) => {
    navigate(`/orders/on-call/detail/${order.id}`, { state: { order } });
  };

  useEffect(() => {
    fetchOrders();
  }, [activeTab]);

  const createNew = () => {
    navigate(`/orders/on-call/create`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRides = orders.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(orders.length / rowsPerPage);

  return (
    <div className="customer-list-container">
      <Sidebar setUser={setUser} />
      <div className="main-content">
        <div className="header">
          <div className="title-container">
            <h1 className="customer-list-title">On-Call Orders List</h1>
            <p className="welcome-text">
              Hello Admin {user ? user.name : "Guest"}
            </p>
          </div>
        </div>
        <div className="header">
          <div className="tabs">
            <button
              className={`tab-button ${
                activeTab === "PENDING" ? "active" : ""
              }`}
              onClick={() => handleTabChange("PENDING")}
              disabled={tabLoading} // Disable tab button when loading
            >
              Pending ({pendingOrdersCount})
            </button>
            <button
              className={`tab-button ${
                activeTab === "CANCELED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("CANCELED")}
              disabled={tabLoading} // Disable tab button when loading
            >
              Cancelled ({canceledOrdersCount})
            </button>
            <button
              className={`tab-button ${
                activeTab === "ACCEPTED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("ACCEPTED")}
              disabled={tabLoading} // Disable tab button when loading
            >
              Active ({acceptedOrdersCount})
            </button>
            <button
              className={`tab-button ${
                activeTab === "COMPLETED" ? "active" : ""
              }`}
              onClick={() => handleTabChange("COMPLETED")}
              disabled={tabLoading} // Disable tab button when loading
            >
              Completed ({completedOrdersCount})
            </button>
          </div>
          <div className="button-update">
            <div className="filter-container">
              <button className="filter-button" onClick={toggleFilter}>
                <span data-feather="filter"></span> Filter
              </button>
            </div>
            <button className="update-button" onClick={createNew}>
              + Create Order
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="filter-options">
            <h5>Filter</h5>
            <div className="filter-option">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={nameFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-option">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={phoneFilter}
                onChange={handleFilterChange}
              />
            </div>
            <div className="filter-buttons">
              <button className="export-button" onClick={handleExportClick}>
                Export
              </button>
              <button className="result-button" onClick={handleResultClick}>
                Result
              </button>
            </div>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">Loading...</div>
        ) : (
          <table
            className={`order-table ${orders.length === 0 ? "no-data" : ""}`}
          >
            <thead>
              <tr>
                <th className="start">Order ID</th>
                <th>Customer</th>
                <th>Driver</th>
                <th>From</th>
                <th>To</th>
                <th>Request Datetime</th>
                <th className="end">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentRides.map((order, index) => (
                <tr key={order.id}>
                  <td className="start">#{indexOfFirstRow + order.code}</td>
                  <td>{order.tbl_User?.name}</td>
                  <td>{order.tbl_Driver?.name || "-"}</td>
                  <td>{order.start_point.name}</td>
                  <td>{order.end_point.name}</td>
                  <td>{new Date(order.created_date).toLocaleString()}</td>
                  <td className="end">
                    {activeTab === "PENDING" ? (
                      <>
                        <button
                          className="edit-button"
                          onClick={() => viewDetails(order)}
                        >
                          Edit
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => handleDeleteClick(order.id)}
                        >
                          Delete
                        </button>
                      </>
                    ) : activeTab === "ACTIVE" ? (
                      <>
                        <button className="going-button">Going</button>
                      </>
                    ) : (
                      <button
                        className="detail-button"
                        onClick={() => viewDetail(order)}
                      >
                        View Details
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <span>
            {currentPage} of {totalPages}
          </span>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {totalPages > 1 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {currentPage - 1}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(currentPage)}
                  disabled={currentPage === 2}
                >
                  {currentPage}
                </button>
              )}

            {totalPages > 2 &&
              currentPage !== totalPages - 1 &&
              currentPage !== totalPages && (
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages - 1}
                >
                  ...
                </button>
              )}

            {totalPages > 2 && (
              <button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage === totalPages - 1}
              >
                {totalPages - 1}
              </button>
            )}

            {totalPages > 1 && (
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {totalPages}
              </button>
            )}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <div className="popup-box">
          <div className="popup-content">
            <p style={{ textAlign: "left" }}>Please Enter the password</p>
            <div className="password">
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <br />
            <div className="condition">
              <p onClick={onCloseDeletePopup}>Cancel</p>
              <p onClick={handleDeleteConfirm}>Ok</p>
            </div>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="second-popup-box">
          <div className="popup-content1">
            <img src={TopupLogo} alt="Logo" className="topup-logo mb-3 mt-3" />
            <div className="password">
              <p>Delete Successful</p>
            </div>
            <div className="successful">
              <p onClick={handleCloseSecondPopup}>Ok</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnCallOrders;
